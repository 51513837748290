import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// local imports

import HamburgerMenu from "./HamburgerMenu";
import SearchBar from "../SearchBar/SearchBar";
import Button from "../Button/Button";
import { GlobalContext } from "../../Context/GlobalContext";
import BookCard from "../Card/BookCard";

// styled-components

const StyledNavbarWrap = styled.div`
  background-color: ${(props) => props.theme.lightBlack};
  height: 60px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  z-index: 999;
`;

const StyledNavbar = styled.nav`
  background-color: ${(props) => props.theme.lightBlack};
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;

  // mobile menu container
  ul.menu {
    display: flex;
    width: ${(props) => props.justify && "100%"};
    justify-content: ${(props) => props.justify && "space-between"};
    align-items: center;
    flex-grow: 1;

    .searchBar {
      display: flex;
      justify-content: flex-end;
      align-self: center;
      flex-grow: 1;
    }
    .menu-auth-links {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }

    .hamburger {
      align-self: center;
    }
  }

  img {
    height: 55px;
    display: block;
  }

  // desktop
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 80%;
  }
`;

const HamburgerIcon = styled.span`
  background-color: transparent;
  flex-grow: 1;
  text-align: right;
  border: none;
  color: ${(props) => props.theme.altYellow};
  font-size: ${(props) => props.theme.largeText};

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    display: none;
  }
`;

const Navbar = () => {
  const location = useLocation();

  // translation methods
  const { t } = useTranslation();
  const { logout, auth, navbarConfig } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [isHambBarOpen, setisHambBarOpen] = useState(false);

  return (
    <StyledNavbarWrap show={navbarConfig.showNavbar}>
      <StyledNavbar justify={location?.pathname === "/"}>
        <ul className="menu">
          {/* drop-down menu */}
          <HamburgerMenu
            isHambBarOpen={isHambBarOpen}
            closeMenu={() => setisHambBarOpen()}
          />
          <li>
            <Link
              to="/"
              onClick={() => {
                setisHambBarOpen(false);
              }}
            >
              <img src="/images/brainster_logo.png" />
            </Link>
          </li>

          {auth && (
            <div className="menu-auth-links">
              <li>
                <Link to={"/books/recommend"}>
                  <Button
                    type={"button"}
                    text={t("menu_buttons.suggest_btn")}
                    className={"btnMenu lg-btn"}
                  />
                </Link>
              </li>

              <li>
                <Link to="/myBooks">
                  <Button
                    type={"button"}
                    text={t("menu_buttons.my_books_btn")}
                    className={"btnMenu lg-btn "}
                  />
                </Link>
              </li>
            </div>
          )}

          <li className="searchBar">
            <SearchBar variant={"small"} />
          </li>

          {!auth && (
            <li>
              <Link to="/signin" className="btnSignIn">
                <Button
                  width={"100%"}
                  type={"button"}
                  variation={"filled"}
                  text={t("menu_buttons.login_btn")}
                  className={"loginBtn lg-btn"}
                />
              </Link>
            </li>
          )}

          {auth && (
            <li>
              <Button
                width={"100%"}
                type={"button"}
                variation={"filled"}
                text={t("menu_buttons.logout_btn")}
                className={"loginBtn lg-btn"}
                onClick={() => {
                  logout();
                  navigate("/");
                }}
              />
            </li>
          )}

          <li className="hamburger">
            <HamburgerIcon
              className="barIcon"
              onClick={() => setisHambBarOpen((prevstate) => !prevstate)}
            >
              {!isHambBarOpen ? <FaBars /> : <FaTimes />}
            </HamburgerIcon>
          </li>
        </ul>
      </StyledNavbar>
    </StyledNavbarWrap>
  );
};
export default Navbar;
