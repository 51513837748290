import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// styled-components
const StyledAddReviewNote = styled.div`
  margin: 30px 0;

  label {
    font-size: ${(props) => props.theme.normalText};
    font-style: oblique;
    margin-bottom: 5px;

    display: inline-block;
  }

  textarea {
    display: block;
    width: 100%;
    border-radius: 15px;
    outline: none;
    margin-top: 10px;
    margin-bottom: 8px;
    color: black;
    padding: 5px;
    border: none;
    box-shadow: 0 -2px 5px 0 ${(props) => props.theme.lightBlack};
  }
  textarea::placeholder {
    font-size: 12px;
    color: ${(props) => props.theme.darkGray};
    font-style: oblique;
  }
`;

const AddReviewNote = ({
  textNote,
  value,
  name,
  id,
  onChange,
  onMouseDown,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <StyledAddReviewNote>
      <label>{t("book_details.reviews.review_labels.comment")}</label>
      <textarea
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        onMouseDown={onMouseDown}
        cols="30"
        rows="5"
        // readonly property prevents the input of filling in case user is not logged in
        // disabled prop holds a boolean if auth is true or false
        readOnly={disabled}
        placeholder={t("book_details.reviews.placeholder")}
      >
        {textNote}
      </textarea>
    </StyledAddReviewNote>
  );
};
export default AddReviewNote;
