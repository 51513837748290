import React from "react";
//npm imports
import styled from "styled-components/macro";

import Wrapper from "../Wrapper/Wrapper";

const LayoutTestWrapper = styled(Wrapper)`
  width: 100%;
`;

// component
const LayoutTestComponent = () => {
  return <LayoutTestWrapper>Test components</LayoutTestWrapper>;
};
export default LayoutTestComponent;
