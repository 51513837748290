const useValidateForm = () => {
    let isValid = true;
    let currentErrors = {}

    const validateForm = (validationRules, userInfo) => {
        for (let key in validationRules) {
            if (validationRules[key] === "required") {
                if (userInfo[key] === "" || userInfo[key] == false) {
                    currentErrors[key] = [];
                    currentErrors[key].push("Полето е задолжително.");
                    if (isValid !== false) {
                        isValid = false;
                    }
                }
            }
        }
        return isValid
    }
    return { validateForm, currentErrors }
}

export default useValidateForm