import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { API } from "../Consts/API";
import { GlobalContext } from "../Context/GlobalContext";
// react-icons
import { BiCommentEdit } from "react-icons/bi";
import { GiNotebook, GiJumpAcross } from "react-icons/gi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { IoIosReturnLeft } from "react-icons/io";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
// local imports
import Button from "../Components/Button/Button";
import AddBookNote from "../Components/BookNote/AddBookNote";
import MyBookNoteCard from "../Components/BookNote/MyBookNoteCard";
import JumpToPage from "../Components/JumpToPage/JumpToPage";
import useMediaCustom from "../Hooks/useMediaCustom";
import Loader from "../Loader/Loader";
import useCustomHeight from "../Hooks/useCustomHeight";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledElectronicBook = styled.div`
  overflow-y: hidden;
  position: relative;

  @media (min-width: ${(props) => props.theme.laptop}) {
    background-color: black;
  }

  .controlButtonsWrap {
    color: ${(props) => props.theme.lightestGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 160px;
  }
  .controlButtonsWrap .addNote,
  .controlButtonsWrap .notes,
  .controlButtonsWrap .jumpPage {
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.5rem;
    margin-right: 7px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }

  .num-pages-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 60px;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    transform: translateX(-50%);
    color: white;

    input[type="text"] {
      -webkit-appearance: none; // turns off the initial styles
      -moz-appearance: none;
      border: none;
      background-color: transparent;
      width: 20px;
      color: ${(props) => props.theme.white};
      padding: 4px 5px;
    }
    input[type="text"]:focus {
      box-shadow: 0 0px 5px 0 ${(props) => props.theme.altYellow};
      border-radius: 4px;
      outline: none;
    }
  }
  .num-pages-container .pageNumber {
    margin: 0 8px;
    align-self: center;
  }

  .num-pages-container button {
    display: inline-block;
  }

  .react-pdf__Document {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-pdf__Page__canvas {
    height: auto !important;
    margin-bottom: 15px;
    border-radius: 5px;
    overflow-x: auto;
  }

  .react-pdf__Page__annotations .annotation {
    display: none !important;
  }

  @media (min-width: ${(props) => props.theme.tablet}) {
    .num-pages-container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media (min-width: ${(props) => props.theme.laptop}) {
    .controlButtonsWrap {
      right: 40px;
    }
  }
`;

const StyledTitle = styled.p`
  font-size: ${(props) => props.theme.normalText};
  font-weight: bold;

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: ${(props) => props.theme.mediumText};
  }
`;

const StyledPopUpBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${(props) => (props.displayed ? "block" : "none")};
  z-index: 1;
  overflow: scroll;

  .allNotes {
    width: clamp(275px, 50%, 450px);
  }
  .allNotes {
    margin: 10rem auto;
  }
  .addNotes {
    margin: 10rem auto;
  }

  .allNotes .titleNotes,
  .addNotes .titleNotes {
    text-align: center;
    color: ${(props) => props.theme.brightYellow};
    font-size: 1.5rem;
  }
  @media (min-width: ${(props) => props.theme.laptop}) {
  }
`;

const StyledPDFContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledZoomButtons = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    margin-left: -1.5px;
    margin-top: 3px;
  }

  @media (min-width: ${(props) => props.theme.tablet}) {
    width: 60px;
    justify-content: space-between;
    margin-right: 20px;
  }
`;
const StyledBackIcon = styled.div`
  font-size: 30px;
  cursor: pointer;
`;
const StyledPageControls = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
const StyledPageNumbers = styled.span`
  margin: 0 5px;
`;
const StyledActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

const ElectronicBook = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isLaptop = useMediaCustom("(min-width: 768px)");
  const heightVh = useCustomHeight();
  const { popupConfig, setPopupConfig, navbarConfig, setNavbarConfig } =
    useContext(GlobalContext);
  //states
  const [numPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfResource, setPdfResource] = useState();
  const [pageScale, setPageScale] = useState(1.2);
  const [isAddBookNoteOpen, setIsAddBookNoteOpen] = useState(false);
  const [isJumpPageOpen, setIsJumpPageOpen] = useState(false);
  const [isAllNotesOpen, setIsAllNotesOpen] = useState(false);
  const [isControlPageContainerOpen, setIsControlPageContainerOpen] =
    useState(true);
  // initial book note values
  const [bookNoteValue, setBookNoteValue] = useState("");
  const [allNotes, setAllNotes] = useState([]);
  // initial server messages
  const serverResponses = { success: [], error: [] };
  const [serverMessages, setServerMessages] = useState(serverResponses);

  const eBookProgressObj = {
    id: "",
    page: "",
  };

  useEffect(() => {
    API.axios
      .get(API.createApiRoute("eBook", { BOOK_ID: id }))
      .then((response) => {
        setAllNotes(response?.data?.data?.notes);
        setPdfResource(response?.data?.data?.resource);
      })
      .catch((error) => console.error(error));

    // if there is book stored in LS and if that book id matches the current book id, set the page number on the page that was last time visited
    if (localStorage.getItem("eBookProgressObj")) {
      const digitalFileLS = JSON.parse(
        localStorage.getItem("eBookProgressObj")
      );
      if (digitalFileLS.id === id) {
        setPageNumber(digitalFileLS.page);
      } else {
        setPageNumber(1);
      }
    }
  }, []);

  useEffect(() => {
    pageNumber > 1 &&
      localStorage.setItem(
        "eBookProgressObj",
        JSON.stringify({ ...eBookProgressObj, id: id, page: pageNumber })
      );
  }, [pageNumber]);

  useEffect(() => {
    if (location.pathname === `/book/${id}/e-book` && isLaptop) {
      setNavbarConfig({
        ...navbarConfig,
        showNavbar: true,
      });
    } else if (location.pathname === `/book/${id}/e-book` && !isLaptop) {
      setNavbarConfig({
        ...navbarConfig,
        showNavbar: true,
      });
    }
  }, [location.pathname, isLaptop]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const prevPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleZoomIn = useCallback(() => {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  }, [pageScale]);

  const handleZoomOut = useCallback(() => {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }, [pageScale]);

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  const handleControlsToolbar = (e) => {
    if (isJumpPageOpen || isAllNotesOpen || isAddBookNoteOpen) {
      setIsControlPageContainerOpen(true);
      !isLaptop &&
        setNavbarConfig({
          ...navbarConfig,
          showNavbar: true,
        });
      return;
    }

    !isLaptop &&
      setNavbarConfig({
        ...navbarConfig,
        showNavbar: !navbarConfig.showNavbar,
      });

    setIsControlPageContainerOpen(!isControlPageContainerOpen);
  };

  // post book note
  const handleBookNote = (e) => {
    e.preventDefault();

    if (bookNoteValue === "") {
      setIsAddBookNoteOpen(false);
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("book_details.book_notes.empty_note_error"),
      });

      return;
    }

    const formData = new FormData();
    formData.append("book_id", id);
    formData.append("comment", bookNoteValue);

    API.axios
      .post(API.createApiRoute("myBookNotes"), formData)
      .then((response) => {
        setServerMessages({
          ...serverMessages,
          success: response?.data.message,
        });
        // taking the last added response object from server and spreading all other objects
        setAllNotes([response?.data?.note, ...allNotes]);
      })
      .catch((error) => {
        setServerMessages({
          ...serverMessages,
          error: error?.response?.data?.message,
        });
      });
    setBookNoteValue("");
    setIsAddBookNoteOpen(false);
  };
  // delete note
  const deleteBookNote = (noteId) => {
    const formData = new FormData();
    formData.append("book_id", id);

    API.axios
      .post(API.createApiRoute("bookNoteDelete", { NOTE_ID: noteId }), formData)
      .then((response) => {
        // created new array with all notes except the current note
        const updatedBookNotes = allNotes.filter(
          (eachNote) => eachNote.id !== noteId
        );
        setAllNotes(updatedBookNotes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <StyledElectronicBook onClick={handleControlsToolbar}>
      <div>
        {pdfResource ? (
          <StyledPDFContainer>
            <Document
              file={pdfResource?.[0]?.link}
              onLoadSuccess={onDocumentLoadSuccess}
              onContextMenu={(e) => e.preventDefault()}
              width={pageScale}
            >
              <Page
                pageNumber={pageNumber}
                vh={heightVh}
                scale={pageScale}
                renderTextLayer={false}
                renderInteractiveForms={false}
              />
            </Document>
          </StyledPDFContainer>
        ) : (
          <div>
            <Loader />
          </div>
        )}
        {!isLaptop &&
          navbarConfig.showNavbar &&
          !isControlPageContainerOpen &&
          setIsControlPageContainerOpen(true)}

        {isControlPageContainerOpen && (
          <div
            className="num-pages-container"
            onClick={(e) => e.stopPropagation()}
          >
            <StyledBackIcon
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/book/${id}`);
              }}
            >
              <IoIosReturnLeft />
            </StyledBackIcon>

            <StyledPageControls>
              <Button
                type={"button"}
                text={<GrFormPrevious />}
                variation={"filled"}
                width={"100%"}
                onClick={prevPage}
                className="pageArrow"
                disabled={pageNumber <= 1}
              />
              <StyledPageNumbers>
                {pageNumber} / {numPages}
              </StyledPageNumbers>

              <Button
                type={"button"}
                text={<GrFormNext />}
                variation={"filled"}
                width={"100%"}
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                className="pageArrow"
              />
            </StyledPageControls>

            <div
              className="controlButtonsWrap"
              onClick={(e) => e.stopPropagation()}
            >
              <StyledZoomButtons>
                <Button
                  type={"button"}
                  text={<AiOutlinePlus />}
                  onClick={handleZoomIn}
                  className={"zoomBtn"}
                />
                <Button
                  type={"button"}
                  text={<AiOutlineMinus />}
                  onClick={handleZoomOut}
                  className={" zoomBtn"}
                />
              </StyledZoomButtons>

              <StyledActionButtons>
                <span
                  className="addNote"
                  onClick={(e) => {
                    setIsAddBookNoteOpen(!isAddBookNoteOpen);
                  }}
                >
                  <BiCommentEdit />
                </span>
                <span
                  className="notes"
                  onClick={() => {
                    setIsAllNotesOpen(!isAllNotesOpen);
                  }}
                >
                  <GiNotebook />
                </span>

                <span
                  className="jumpPage"
                  onClick={() => setIsJumpPageOpen(!isJumpPageOpen)}
                >
                  <GiJumpAcross />
                </span>
              </StyledActionButtons>
            </div>
          </div>
        )}
        {isAddBookNoteOpen && (
          <StyledPopUpBox
            displayed={isAddBookNoteOpen}
            onClick={() => {
              setIsAddBookNoteOpen(false);
            }}
          >
            <div className="addNotes">
              <StyledTitle className="titleNotes ">
                {t("book_details.book_notes.add_note_label")}
              </StyledTitle>
              <AddBookNote
                onSubmit={handleBookNote}
                textNote={bookNoteValue}
                onChange={(e) => setBookNoteValue(e.target.value)}
                className="addNoteInPdf"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </StyledPopUpBox>
        )}

        {isAllNotesOpen && (
          <StyledPopUpBox
            displayed={isAllNotesOpen}
            onClick={() => {
              setIsAllNotesOpen(false);
            }}
          >
            <div className="allNotes">
              <StyledTitle className="titleNotes ">
                {t("book_details.book_notes.notes_label")}
              </StyledTitle>
              {allNotes?.map((bookNote) => {
                return (
                  <MyBookNoteCard
                    key={bookNote.id}
                    noteId={bookNote.id}
                    content={bookNote?.comment}
                    date={bookNote?.created_at}
                    bookId={id}
                    deleteNote={deleteBookNote}
                    setIsAllNotesOpen={setIsAllNotesOpen}
                    setAllNotes={setAllNotes}
                    onClick={(e) => e.stopPropagation()}
                  />
                );
              })}
            </div>
          </StyledPopUpBox>
        )}

        {isJumpPageOpen && (
          <StyledPopUpBox
            displayed={isJumpPageOpen}
            onClick={() => {
              setIsJumpPageOpen(false);
            }}
          >
            <JumpToPage
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setIsJumpPageOpen={setIsJumpPageOpen}
            />
          </StyledPopUpBox>
        )}
      </div>
    </StyledElectronicBook>
  );
};

export default ElectronicBook;
