import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// local imports
import Button from "../Button/Button";
import { GlobalContext } from "../../Context/GlobalContext";

// styled-component
// style for layout
const StyledAlertBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${(props) => (props.displayed ? "block" : "none")};
  z-index: 1;

  /* style for X icon */
  svg {
    color: white;
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 55px;
  }
`;
// style for the message box
const StyledAlertBody = styled.aside`
  width: 260px;
  height: 100px;
  position: fixed;
  top: 40vh;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  box-shadow: 1px 0px 10px 0px #ffea00, 1px 0px 20px 7px black;
  background-color: ${(props) => props.theme.altYellow};
  color: ${(props) => props.theme.black};
  display: ${(props) => (props.displayed ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  transition-duration: 0.5s;
  transition-property: display;
  transition-timing-function: background-color;

  p {
    padding: 10px;
  }

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 360px;
    height: 150px;
  }
  // desktop
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 20%;
  }
`;

const PopUpMessage = () => {
  const { popupConfig, setPopupConfig } = useContext(GlobalContext);
  const { t } = useTranslation();
  return (
    <StyledAlertBox
      displayed={popupConfig.show}
      // to close the dilog on click anywhere on the screen
      onClick={() => setPopupConfig({ ...popupConfig, show: false })}
    >
      <FaTimes
        onClick={() => setPopupConfig({ ...popupConfig, show: false })}
      />
      <StyledAlertBody
        displayed={popupConfig.show}
        onClick={(e) => e.stopPropagation()}
      >
        <p>{popupConfig.message}</p>
        {popupConfig.confirmation && (
          <div>
            <Button
              type="button"
              text={t("confirm_popup.approve")}
              onClick={() => {
                popupConfig.approveAction();
                setPopupConfig({
                  ...popupConfig,
                  show: false,
                  confirmation: false,
                });
              }}
              className={"confirm-delete-btn"}
            />
            <Button
              type="button"
              text={t("confirm_popup.reject")}
              onClick={() => {
                popupConfig.rejectAction();
                setPopupConfig({
                  ...popupConfig,
                  show: false,
                  confirmation: false,
                });
              }}
              className={"confirm-delete-btn"}
            />
          </div>
        )}
      </StyledAlertBody>
    </StyledAlertBox>
  );
};
export default PopUpMessage;
