import React from "react";
import styled from "styled-components";

const StyledRadioButton = styled.div`
  /* center label and button */
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: ${(props) => props.theme.normalText};
  /* input style */
  input {
    /* remove default radio button style */
    -webkit-appearance: none;
    appearance: none;
    /* add custom style for radio button*/
    cursor: pointer;
    margin-bottom: 3px;
    background-color: #fff;
    margin: 0;
    color: ${(props) => props.theme.yellow};
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid ${(props) => props.theme.yellow};
    border-radius: 50%;
    display: grid;
    place-content: center;
    &:hover {
      box-shadow: 0 0 5px 0px ${(props) => props.theme.yellow};
    }
    &::before {
      content: "";
      display: block;
      width: 0.6em;
      height: 0.6em;
      border-radius: 50%;
      background-color: ${(props) => props.theme.yellow};
      /* before is checked */
      transform: scale(0);
    }
    &:checked::before {
      /* after is checked */
      transform: scale(1);
    }
  }
  /* label style */
  label {
    cursor: pointer;
    color: ${(props) => props.theme.black};
    margin-left: 8px;
  }
`;

const RadioButton = ({ id, name, onChange, value, label, checked }) => {
  return (
    <StyledRadioButton>
      <input
        type="radio"
        name={name}
        id={id}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <label htmlFor={id}>{label}</label>
    </StyledRadioButton>
  );
};

export default RadioButton;
