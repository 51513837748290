import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// local imports
import Button from "../Button/Button";

// styled components
const StyledForm = styled.form`
  margin: 30px 0;

  &.addNoteInPdf {
    margin: 30px auto;
    width: clamp(275px, 50%, 450px);
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

// textarea style
const StyledBookNote = styled.textarea`
  display: block;
  width: 100%;
  border-radius: 15px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 8px;
  color: black;
  padding: 5px;
  border: none;
  box-shadow: 0 -2px 5px 0 ${(props) => props.theme.lightBlack};
`;

const AddBookNote = ({
  textNote,
  name,
  onChange,
  onSubmit,
  className,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledForm
      action=""
      onSubmit={onSubmit}
      className={className}
      onClick={onClick}
    >
      <StyledBookNote
        value={textNote}
        name={name}
        onChange={onChange}
        cols="30"
        rows="5"
        placeholder={t("book_details.book_notes.placeholder")}
      />

      <Button
        type={"submit"}
        text={t("book_details.book_notes.submit_btn")}
        variation={"filled"}
        className={"addNote"}
        width={"550px"}
      />
    </StyledForm>
  );
};
export default AddBookNote;
