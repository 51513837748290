import axios from "axios";

let axiosSetup = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// axios request setup
axiosSetup.interceptors.request.use(
  function (config) {
    /**
     * send auth token with every request
     */
    config.headers.Authorization =
      "Bearer " + localStorage.getItem("userToken");

    return config;
  },
  function (error) {
    // handle request error
    return Promise.reject(error);
  }
);

// axios response setup
axiosSetup.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // session expired
    // status 401 - unauthorized user
    if (error.response && error.response.status === 401) {
      // remove auth user token from local storage
      localStorage.removeItem("userToken");
    }

    /**
     * We must return rejected error promise so we can handle
     * errors depending on the status code in signin/register components
     */
    return Promise.reject(error);
  }
);

const createApiRoute = (route, data) => {
  let apiRoute = API.routes[route];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      apiRoute = apiRoute.replace(`{${key}}`, data[key]);
    }
  }

  return apiRoute;
};

const createPath = (path, data) => {
  let apiRoute = API.paths[path];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      apiRoute = apiRoute.replace(`{${key}}`, data[key]);
    }
  }

  return apiRoute;
};

export const API = {
  axios: axiosSetup,
  createApiRoute: createApiRoute,
  createPath: createPath,
  paths: {
    home: "/",
    signin: "/signin",
    register: "/register",
    bookDetails: "/book/{BOOK_ID}",
    bookID: "/books/{book}",
    recommendBook: "/books/recommend",
    eBook: "/book/{BOOK_ID}/e-book",
  },
  routes: {
    login: "/login",
    logout: "/logout",
    register: "/register",
    categories: "/categories",
    books: "/books",
    bookID: "/books/{BOOK_ID}",
    authors: "/authors",
    selectedBookCategory: "/books/{CATEGORY_ID}",
    selectedAuthor: "/authors/{author}",
    myBooks: "/myBooks",
    reviewBook: "/books/{BOOK_ID}/review",
    bookReviewDelete: "/review/{BOOK_REVIEW_ID}/delete",
    bookProposals: "/bookProposals",
    wishlist: "/books/{BOOK_ID}/wishlist",
    reserveBook: "/books/{BOOK_ID}/reserve",
    myBooksInfo: "/myBooks/{BOOK_ID}/info",
    myBookNotes: "/notes",
    bookNoteEdit: "/notes/{NOTE_ID}/edit",
    bookNoteDelete: "/notes/{NOTE_ID}/delete",
    bookResources: "book/{BOOK_ID}/resources",
    eBook: "book/{BOOK_ID}/e-book",
  },
  storage: process.env.REACT_APP_STORAGE_URL,
};
