// setting styling properties
const theme = {
  white: "#FFFFFF",
  gray: "#C6C6C6",
  veryLightGray: "#EEEEEE",
  lightestGray: "#F9F9F9",
  textGray: "#B2B2B2",
  darkGray: "#8B8B8B",
  altYellow: "#F5EB5D",
  yellow: "#F2E635",
  brightYellow: "#F9F39A",
  black: "#161616",
  lightBlack: "#2D2E34",
  active: "#005EFF",
  textError: "#FF003A",
  smallText: "10px",
  normalText: "14px",
  mediumText: "20px",
  largeText: "24px",
  heading1: "14px",
  heading2: "16px",
  heading3: "22px",
  heading4: "28px",
  heading5: "35px",

  // media query
  tablet: "550px",
  laptop: "768px",
  desktop: "992px",
};

export default theme;
