import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
// local imports
import LayoutTestComponent from "./Components/LayoutTest/LayoutTestComponent";
import GlobalStyle from "./Consts/GlobalStyle";
import theme from "./Consts/Theme";
import Register from "./Pages/Register";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar/Navbar";
import SignIn from "./Pages/SignIn";
import MyBooks from "./Pages/MyBooks";
import BookDetails from "./Pages/BookDetails";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";
import RecommendBook from "./Pages/RecommendBook";
import PopUpMessage from "./Components/Notification/PopUpMessage";
import ElectronicBook from "./Pages/ElectronicBook";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Router>
        <ScrollToTop>
          <Navbar />
          <PopUpMessage />
          <Routes>
            {/* public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/layout" element={<LayoutTestComponent />} />
            <Route path="/book/:id" element={<BookDetails />} />
            {/* private routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/myBooks" element={<MyBooks />} />
              <Route path="/books/recommend" element={<RecommendBook />} />
              <Route path="/book/:id/e-book" element={<ElectronicBook />} />
            </Route>

            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  );
}

export default App;
