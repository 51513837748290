import React, { useState, useContext, useEffect } from "react";
import { FaEdit, FaTrash, FaSave, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { API } from "../../Consts/API";
import { GlobalContext } from "../../Context/GlobalContext";
import { useTranslation } from "react-i18next";

// styled-component
// layout style
const StyledBookNoteCard = styled.article`
  border: none;
  box-shadow: 0px 0px 6px 0px ${(props) => props.theme.lightBlack};
  background-color: white;
  border-radius: 15px;
  padding: 10px 5px;
  color: ${(props) => props.theme.darkGray};
  position: relative;
  margin-top: 20px;

  // title style
  h4 {
    font-weight: 500;
  }
  // content style
  p {
    font-style: italic;
    font-size: 12px;
  }
  // date style
  span.dateEdited {
    display: flex;
    justify-content: end;
    margin: 30px 10px 5px 0;
    font-size: ${(props) => props.theme.smallText};
  }
  // textarea
  textarea {
    border: none;
    color: black;
    outline: none;
    width: 100%;
    height: 100%;
  }
`;
// edit icon style
const StyledEditIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.veryLightGray};
  color: ${(props) => props.theme.lightBlack};
  font-size: 13px;
  box-shadow: 0px 1px 7px 0px ${(props) => props.theme.lightBlack};
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
`;
// delete icon style
const StyledDeleteIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.veryLightGray};
  color: ${(props) => props.theme.lightBlack};
  font-size: 13px;
  box-shadow: 0px 1px 7px 0px ${(props) => props.theme.lightBlack};
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: 30px;
`;
// cancel icon
const StyledCancelIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.veryLightGray};
  color: ${(props) => props.theme.lightBlack};
  font-size: 13px;
  box-shadow: 0px 1px 7px 0px ${(props) => props.theme.lightBlack};
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: 30px;
`;

const MyBookNoteCard = ({
  noteId,
  bookId,
  content,
  date,
  deleteNote,
  onClick,
  setIsAllNotesOpen,
  setAllNotes,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState(content);
  const { popupConfig, setPopupConfig } = useContext(GlobalContext);
  const { t } = useTranslation();

  // post edited book note
  const handleBookNote = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("book_id", bookId);
    formData.append("comment", editedValue);

    API.axios
      .post(API.createApiRoute("bookNoteEdit", { NOTE_ID: noteId }), formData)
      .then((response) => {
        setAllNotes(response?.data?.notes);
      })
      .catch((error) => {
        console.log(error);
        setEditedValue(content);
      });
  };

  return (
    <>
      {editMode ? (
        <StyledBookNoteCard onClick={onClick}>
          <textarea
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
          >
            {/* {content} */}
          </textarea>
          {/* cancel icon */}
          <StyledCancelIcon
            onClick={() => {
              setEditedValue(content);
              setEditMode(false);
            }}
          >
            <FaTimes />
          </StyledCancelIcon>
          {/* save icon */}
          <StyledEditIcon
            onClick={(e) => {
              handleBookNote(e);
              setEditMode(false);
            }}
          >
            <FaSave />
          </StyledEditIcon>
        </StyledBookNoteCard>
      ) : (
        <StyledBookNoteCard onClick={onClick}>
          <p>{editedValue}</p>
          <span className="dateEdited">
            {t("book_details.book_notes.updated_message")} {date}
          </span>
          {/* edit icon */}
          <StyledEditIcon onClick={() => setEditMode(true)}>
            <FaEdit />
          </StyledEditIcon>
          {/* delete icon */}
          <StyledDeleteIcon
            onClick={() => {
              setIsAllNotesOpen && setIsAllNotesOpen(false);
              setPopupConfig({
                ...popupConfig,
                show: true,
                confirmation: true,
                message: t("delete-note.confirm_delete"),
                approveAction: () => {
                  deleteNote(noteId);
                },
              });
            }}
          >
            <FaTrash />
          </StyledDeleteIcon>
        </StyledBookNoteCard>
      )}
    </>
  );
};

export default MyBookNoteCard;
