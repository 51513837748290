import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  color: ${(props) => props.theme.black};
  padding: 15px 10px;
  border-radius: 25px;
  outline: none;
  border: 1px solid
    ${(props) => (props.invalidInput ? "red" : props.theme.gray)};
  margin-top: ${(props) => props.className && "10px"};
  width: ${(props) => props.width};

  &:focus {
    border: 1px solid
      ${(props) => (props.invalidInput ? "red" : props.theme.yellow)};
    box-shadow: 0 0 5px
      ${(props) => (props.invalidInput ? "red" : props.theme.yellow)};
  }
`;

const InputComponent = ({
  type,
  onChange,
  placeholder,
  checked,
  required,
  invalidInput,
  className,
  value,
  width,
  name,
  id,
}) => {
  return (
    <StyledInput
      type={type}
      onChange={onChange}
      placeholder={required ? `${placeholder}*` : placeholder}
      className={className}
      value={value}
      width={width}
      name={name}
      id={id}
      checked={checked}
      invalidInput={invalidInput}
    />
  );
};
export default InputComponent;
