import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { css } from "styled-components";

// style-components
const StyledProgressContainer = styled.div`
  ${({ variation }) =>
    variation === "audioTrack"
      ? css`
          width: 100%;
        `
      : variation === "volumeTrack" &&
        css`
          width: 50%;
          display: flex;
          justify-content: flex-end;
        `}

  input[type="range"] {
    -webkit-appearance: none; // tunrs off the initial styles
    background-color: transparent;
    width: 100%;
    margin: 18px 0;
    position: relative;
  }

  // Chrome and Safari styles for the truck
  input[type="range"]::-webkit-slider-runnable-track {
    height: 5px;
    width: 100%;
    cursor: pointer;
    background-color: ${(props) => props.theme.textGray};
    border-radius: 4px;
  }

  /* progress bar - chrome and safari */
  .progress::before {
    content: "";
    height: 5px;
    width: ${(props) => (props.progressWidth ? `${props.progressWidth}%` : 0)};
    transition: width 0.1s linear;
    background-color: purple;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  // Chrome and Safari styles for the thumb
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: purple;
    margin-top: -6px;
  }

  // Firefox styles for the track
  input[type="range"]::-moz-range-track {
    -webkit-appearance: none;
    height: 5px;
    width: 100%;
    cursor: pointer;
    background-color: pink;
    border-radius: 4px;
  }
  // Firefox styles for the thumb
  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: purple;
    margin-top: -6px;
  }

  // IE styles for the truck
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    height: 5px;
    width: 100%;
    cursor: pointer;
    background-color: pink;
    border-radius: 4px;
  }
  // IE styles for the thumb
  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: purple;
    margin-top: -6px;
  }
`;
const Slider = ({ currentTime, duration, onChange, volume, variation }) => {
  const progressBarRef = useRef();
  const [progressWidth, setProgressWidth] = useState(0);

  // updates the progress width persentage everytime the currentTime changes
  useEffect(() => {
    progressBarRef.current.max = duration;
    progressBarRef.current.value = currentTime;
    setProgressWidth((currentTime / duration) * 100);
  }, [duration, currentTime]);

  useEffect(() => {
    if (volume) {
      progressBarRef.current.max = 100;
      setProgressWidth(volume);
    }
  }, [volume]);

  return (
    <StyledProgressContainer
      progressWidth={progressWidth}
      variation={variation}
    >
      <input
        type="range"
        defaultValue="0"
        ref={progressBarRef}
        onChange={() => onChange(progressBarRef.current)}
        className={"progress"}
      />
    </StyledProgressContainer>
  );
};

export default Slider;
