import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
// local imports
import { GlobalContext } from "../../Context/GlobalContext";
import Button from "../Button/Button";

//styled-component
const StyledHamburgerMenu = styled.ul`
  width: 100vw;
  background-color: ${(props) => props.theme.lightBlack};
  height: ${(props) => (props.displayBar ? "100vh" : "0")};
  transition: height 0.3s linear, margin 0.1s linear;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 55px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  padding-top: ${(props) => (props.displayBar ? "20px" : "0")};
  padding-left: 25px;
  padding-right: 25px;
  z-index: 11;

  li {
    margin-top: 15px;
    text-align: center;
    font-size: 35px;
  }
`;

const HamburgerMenu = (props) => {
  const { t } = useTranslation();
  const { logout, auth } = useContext(GlobalContext);

  return (
    <StyledHamburgerMenu displayBar={props.isHambBarOpen}>
      {auth && (
        <li onClick={() => props.closeMenu((prevstate) => !prevstate)}>
          <Link to={"/books/recommend"}>
            <Button
              type={"button"}
              text={t("menu_buttons.suggest_btn")}
              className={"btnMenu"}
            />
          </Link>
        </li>
      )}
      {auth && (
        <li onClick={() => props.closeMenu((prevstate) => !prevstate)}>
          <Link to="/myBooks">
            <Button
              type={"button"}
              text={t("menu_buttons.my_books_btn")}
              className={"btnMenu"}
            />
          </Link>
        </li>
      )}
      {!auth && (
        <li onClick={() => props.closeMenu((prevstate) => !prevstate)}>
          <Link to="/signin" className="btnSignIn">
            <Button
              width={"100%"}
              type={"button"}
              variation={"filled"}
              text={t("menu_buttons.login_btn")}
              className={"loginBtn"}
            />
          </Link>
        </li>
      )}
      {auth && (
        <li onClick={() => props.closeMenu((prevstate) => !prevstate)}>
          <Link to="/">
            <Button
              width={"100%"}
              type={"button"}
              variation={"filled"}
              text={t("menu_buttons.logout_btn")}
              className={"loginBtn"}
              onClick={() => {
                logout();
              }}
            />
          </Link>
        </li>
      )}
    </StyledHamburgerMenu>
  );
};
export default HamburgerMenu;
