import React, { useEffect, useState } from "react";
import BookCard from "../Components/Card/BookCard";
import { API } from "../Consts/API";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledMyBooks = styled.div`
  padding: 10px 0px;
  text-align: center;
  > p {
    font-size: ${(props) => props.theme.largeText};
  }

  // desktop
  @media (min-width: ${(props) => props.theme.desktop}) {
    padding: 50px 0px;

    > p {
      font-size: ${(props) => props.theme.heading5};
    }
  }
`;

const StyledBookCardContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  width: 90%;
  margin: 50px auto;

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  // desktop
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 80%;
  }
`;

const StyledTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 10px;
  cursor: pointer;

  .tab {
    flex: 1 1 0;
    padding: 5px;
    font-size: ${(props) => props.theme.normalText};
    color: ${(props) => props.theme.lightBlack};
    opacity: 0.5;
    border-bottom: 3px solid transparent;
  }

  .tab:hover {
    border-bottom: 3px solid ${(props) => props.theme.yellow};
  }

  .active-tab {
    border-bottom: 3px solid ${(props) => props.theme.yellow};
    opacity: 1;
  }

  // desktop
  @media (min-width: ${(props) => props.theme.desktop}) {
    padding: 40px 230px;

    .tab {
      font-size: ${(props) => props.theme.mediumText};
    }
  }
`;

const MyBooks = () => {
  const { t } = useTranslation();
  const [myBooks, setMyBooks] = useState(undefined);
  const [books, setBooks] = useState();
  const tabs = [
    {
      id: 1,
      name: "wishlist",
    },
    {
      id: 2,
      name: "reservations",
    },
    {
      id: 3,
      name: "rented",
    },
    {
      id: 4,
      name: "notes",
    },
  ];

  useEffect(() => {
    API.axios
      .get(API.createApiRoute("myBooks"))
      .then((response) => {
        // all books
        setMyBooks(response.data);
        // books in active tab
        setBooks(response.data.wishlist);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <StyledMyBooks>
      <p>Мои книги</p>
      <StyledTabs>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab ${
              typeof myBooks === "object" &&
              books === myBooks[tab.name] &&
              "active-tab"
            } `}
            onClick={() =>
              typeof myBooks === "object" && setBooks(myBooks[tab.name])
            }
          >
            {t(`my_books.tabs.${tab.id}`)}
          </div>
        ))}
      </StyledTabs>
      <StyledBookCardContainer>
        {books?.map((book) => (
          <BookCard key={book.id} book={book} />
        ))}
      </StyledBookCardContainer>
    </StyledMyBooks>
  );
};
export default MyBooks;
