import React from "react";
import { FaHeart } from "react-icons/fa";
import { BsSuitHeart } from "react-icons/bs";
import styled from "styled-components";

// styled components
const StyledBookmark = styled.label`
  background-color: transparent;
  color: ${(props) =>
    props.colored ? props.theme.textError : props.theme.lightBlack};
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: 30px;
  padding: 10px 0;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 20px;
  input {
    display: none;
  }
`;
const Bookmark = ({ colored, onChange, checked, onMouseDown }) => {
  return (
    <StyledBookmark
      colored={colored}
      checked={checked}
      onMouseDown={onMouseDown}
    >
      <input type={"checkbox"} onChange={onChange} name="bookmark" />
      {checked ? <FaHeart /> : <BsSuitHeart />}
    </StyledBookmark>
  );
};
export default Bookmark;
