import React from "react";
import styled from "styled-components";

const StyledErrorMessage = styled.span`
  color: ${(props) => props.theme.textError};
  font-size: 12px;
  margin: 7px 5px;
  display: inline-block;
`;

const ErrorMessage = ({ message }) => {
  return <StyledErrorMessage>{message}</StyledErrorMessage>;
};

export default ErrorMessage;
