import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { API } from "../Consts/API";
import { useTranslation } from "react-i18next";
import { BsBookmarkCheck } from "react-icons/bs";
import { FaHeadphones } from "react-icons/fa";
import { BiBookReader } from "react-icons/bi";
import styled from "styled-components";
// local imports
import Button from "../Components/Button/Button";
import StarRatingIcon from "../Components/Icon/StarRatingIcon";
import StarRatesContainer from "../Components/Icon/StarRatesContainer";
import { GlobalContext } from "../Context/GlobalContext";
import AddBookNote from "../Components/BookNote/AddBookNote";
import MyBookNoteCard from "../Components/BookNote/MyBookNoteCard";
import AddReviewNote from "../Components/ReviewNotes/AddReviewNote";
import ReviewNoteCard from "../Components/ReviewNotes/ReviewNoteCard";
import Bookmark from "../Components/Icon/Bookmark";
import AudioPlayer from "../Components/Audio/AudioPlayer";
import useMediaCustom from "../Hooks/useMediaCustom";

// styled-components
const BookDetailsContainer = styled.div`
  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
  }
`;
const StyledBookNoteContainer = styled.div`
  display: grid;
  column-gap: 30px;
  grid-template-columns: 1fr;

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const StyledBookDetailsLayout = styled.div`
  font-size: ${(props) => props.theme.mediumText};
  position: relative;
  a.read-more {
    border-bottom: 3px solid ${(props) => props.theme.lightBlack};
    font-size: ${(props) => props.theme.normalText};
  }
  .book-info-text {
    margin-top: 5px;
    font-size: ${(props) => props.theme.normalText};
  }
`;
const StyledBookInfo = styled.ul`
  display: flex;
  text-align: center;
  border-radius: 10px;
  padding: 10px;

  li {
    flex-basis: 100%;
    :not(:last-child) {
      border-right: 1px solid black;
    }
    // laptop
    @media only screen and (min-width: ${(props) => props.theme.laptop}) {
      font-size: 14px;
      padding-right: 8px;
      :not(:first-child) {
        padding-left: 8px;
      }
    }
  }

  li span {
    color: ${(props) => props.theme.textGray};
    // laptop
    @media only screen and (min-width: ${(props) => props.theme.laptop}) {
      color: ${(props) => props.theme.lightBlack};
    }
  }

  li p span {
    color: ${(props) => props.theme.darkGray};
  }
`;
const StyledBookDescription = styled.div`
  margin-top: 15px;
  line-height: 1.5;
  font-style: italic;
`;
const StyledTitle = styled.p`
  font-size: ${(props) => props.theme.normalText};
  font-weight: bold;

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: ${(props) => props.theme.mediumText};
  }
`;
const StyledAboutTheAuthor = styled.div`
  margin: 15px 0px;
  background-color: ${(props) => props.theme.veryLightGray};
  border-radius: 15px;
  padding: 10px;

  h6 {
    font-style: italic;
  }

  p {
    margin-top: 15px;
    line-height: 1.5;
  }

  .author-container div {
    margin: 10px 0px;
  }

  .author-container img {
    width: 50px;
    display: block;
    border-radius: 50px;
  }
  .author-container p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  div span {
    align-self: center;
    padding-left: 15px;
  }
`;
const StyledReviews = styled.div`
  margin-top: 15px;
  background-color: ${(props) => props.theme.veryLightGray};
  border-radius: 15px;
  padding: 10px;

  h3 {
    font-size: ${(props) => props.theme.mediumText};
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .styled-label {
    font-size: ${(props) => props.theme.normalText};
    font-style: oblique;
    margin-bottom: 15px;
    display: inline-block;
  }
  textarea {
    display: block;
    width: 100%;
    border-radius: 15px;
    outline: none;
    margin-top: 10px;
    color: black;
    padding: 5px;
    border: none;
    box-shadow: 0 -2px 5px 0 ${(props) => props.theme.lightBlack};
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .error-text {
    color: ${(props) => props.theme.textError};
    font-size: ${(props) => props.theme.smallText};
    margin: 8px 0px;
  }
`;
const StyledStarIconBox = styled.article`
  padding-right: 70px;
`;

const StyledBookDetailsInnerWrap = styled.div`
  display: flex;
  padding: 50px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(175deg, #f4f4f2, #b4b475);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  h3.title {
    font-size: 16px;
    color: #161616;
    text-align: center;
    margin-top: 15px;
    font-style: oblique;
    font-weight: bold;
    // laptop
    @media only screen and (min-width: ${(props) => props.theme.laptop}) {
      text-align: left;
      font-size: 20px;
      margin-top: 0;
    }
  }
  p.author {
    font-size: ${(props) => props.theme.normalText};
    color: ${(props) => props.theme.lightBlack};
    margin-top: 5px;
    text-align: center;
    // laptop
    @media only screen and (min-width: ${(props) => props.theme.laptop}) {
      text-align: left;
    }
  }
  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;
const StyledImageContainer = styled.div`
  img {
    max-width: 100%;
    display: block;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    // laptop
    @media only screen and (min-width: ${(props) => props.theme.laptop}) {
      height: 300px;
    }
  }
  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    align-self: flex-start;
    flex: 0 0 30%;
  }
`;
const StyledBookTypeButtonsContainer = styled.div`
  background-color: ${(props) => props.theme.lightBlack};
  border-radius: 10px;
  padding: 15px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  transform: none;
  position: relative;
  margin: -50px auto 0;
  left: 50%;
  transform: translateX(-50%);

  svg {
    font-size: 25px;
    color: darkslateblue;
  }
`;

const StyledMainBookDetailsContainer = styled.div`
  padding: 0 15px;

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding: 0;
  }
`;
const StyledBookInfoContainer = styled.div`
  background-color: ${(props) => props.theme.lightestGray};
  width: 90%;
  margin: 60px auto 0;
  .read-more-details {
    text-align: right;
  }
  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    background-color: transparent;
    width: 100%;
    margin: 30px 0 0;
  }
`;
const StyledBookDetailsMoreInfo = styled.ul`
  padding: 0 8px 5px;
  li {
    margin-top: 10px;
    font-size: 16px;
  }
  :not(:last-child) {
    margin-bottom: 8px;
  }
  li span {
    font-weight: bold;
    margin-right: 5px;
  }
`;
const StyledTitleAuthorContainer = styled.div`
  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    height: 100%;
    margin-left: 30px;
    align-self: flex-start;
    flex: 0 0 40%;
  }
`;
const StyledInnerButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  :not(:last-child) {
    margin-right: 15px;
  }

  p {
    margin-top: 5px;
    font-size: 10px;
  }
`;
// number of stars that will be displayed and iterated
const starIconsTotal = 5;

export default function BookDetails() {
  // useParams hook returns object that contains id
  let { id } = useParams();
  const { t } = useTranslation();
  // we need location pathname to triger AJAX requests everytime the location pathname is changed
  const location = useLocation();
  const { auth, popupConfig, setPopupConfig, navbarConfig, setNavbarConfig } =
    useContext(GlobalContext);
  const isLaptop = useMediaCustom("(min-width: 768px)");

  // * --------------------------- *
  //stores current book object
  const [currenBookDetails, setCurrenBookDetails] = useState();
  const [isReadMore, setIsReadMore] = useState(false);
  // storing the index of hovered star
  const [hoverdStarIndex, setHoverdStarIndex] = useState(undefined);
  // stores the status of the wishlist icon
  const [isBookMarked, setIsBookMarked] = useState(false);
  const [isReserved, setIsReserved] = useState(false);

  const [myReview, setMyReview] = useState(false);

  // * ---------------------------- *
  const [isAudioBook, setIsAudioBook] = useState(false);
  const [currentBookResources, setCurrentBookResources] = useState([]);

  // * --------------------------- *
  // initail book review values
  const emptyBookReviewValues = {
    bookReviewValue: "",
    selectedStarRate: undefined,
  };
  const [bookReview, setBookReview] = useState(emptyBookReviewValues);
  // initial server messages
  const serverResponses = { success: [], error: [] };
  const [serverMessages, setServerMessages] = useState(serverResponses);
  // initial book note values
  const [bookNoteValue, setBookNoteValue] = useState("");
  const [allNotes, setAllNotes] = useState([]);

  // get current book object by id
  useEffect(() => {
    if (id !== currenBookDetails?.id) {
      API.axios
        .get(API.createApiRoute("bookID", { BOOK_ID: id }))
        .then((response) => setCurrenBookDetails(response))
        .catch((error) => console.error(error));
    }
  }, [location.pathname]);

  useEffect(() => {
    // for logged in user
    if (auth) {
      API.axios
        .get(API.createApiRoute("myBooksInfo", { BOOK_ID: id }))
        .then((response) => {
          setIsReserved(response.data.data.in_reservation);
          setIsBookMarked(response.data.data.in_wishlist);
          setAllNotes(response?.data?.data?.notes);
          setMyReview(response.data.data.review[0]);
        })
        .catch((error) => console.error(error));

      API.axios
        .get(API.createApiRoute("bookResources", { BOOK_ID: id }))
        .then((response) => {
          setCurrentBookResources(response?.data);
        })
        .catch((error) => console.error(error));
    }
  }, [auth]);

  useEffect(() => {
    setNavbarConfig({
      ...navbarConfig,
      showNavbar: true,
    });
  }, []);
  // submit book review note and star rate
  const onBookReviewSubmit = (e) => {
    e.preventDefault();

    // dont send AJAX request if user is not auth OR if rate is <= 3 and review comment is empty OR
    // if user left a comment but not a rate OR if there is no rate and no comment
    if (!auth) {
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("books.login_to_comment"),
      });
      return;
    }
    if (
      bookReview.selectedStarRate <= 3 &&
      bookReview?.bookReviewValue?.length === 0
    ) {
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("rating.rate_and_comment"),
      });
      return;
    }
    if (
      bookReview.selectedStarRate === undefined &&
      bookReview?.bookReviewValue?.length > 0
    ) {
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("star.select_star"),
      });
      return;
    }
    if (
      bookReview.selectedStarRate === undefined &&
      bookReview?.bookReviewValue?.length === 0
    ) {
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("form.form_review"),
      });
      return;
    }

    // send AJAX request only if rate is <= 3 and review comment is not empty OR if rate is >=4 with or without review comment
    // send request to server
    const formData = new FormData();
    formData.append("comment", bookReview.bookReviewValue);
    formData.append("rating", bookReview.selectedStarRate);

    API.axios
      .post(API.createApiRoute("reviewBook", { BOOK_ID: id }), formData)
      .then((response) => {
        // store the success response from server
        setServerMessages({
          ...serverMessages,
          success: response?.data?.message,
        });

        setPopupConfig({
          ...popupConfig,
          show: true,
          message: response?.data?.message,
        });
        // reset form input values if successfully submited (user logged in)
        setBookReview(emptyBookReviewValues);
        setMyReview(response.data.review);
      })
      .catch((error) => {
        // additional error - unknown status
        setServerMessages({
          ...serverMessages,
          error: `${t("server_messages.unknown_error")}`,
        });

        setPopupConfig({
          ...popupConfig,
          show: true,
          message: `${t("server_messages.unknown_error")}`,
        });
      });
  };
  // delete book review
  const handleDeleteBookReview = () => {
    API.axios
      .post(
        API.createApiRoute("bookReviewDelete", {
          BOOK_REVIEW_ID: myReview.id,
        })
      )
      .then((response) => {
        setMyReview(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // reserve book
  const handleReserveBook = () => {
    if (auth) {
      API.axios
        .post(API.createApiRoute("reserveBook", { BOOK_ID: id }))
        .then((response) => {
          setServerMessages({
            ...serverMessages,
            success: response?.data.message,
          });

          setPopupConfig({
            ...popupConfig,
            show: true,
            message: response?.data.message,
          });

          setIsReserved(true);
        })
        .catch((error) => {
          setServerMessages({
            ...serverMessages,
            error: error?.response?.data?.message,
          });

          setPopupConfig({
            ...popupConfig,
            show: true,
            message: error?.response?.data?.message,
          });
        });
    } else {
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("book_details.reserve_popup"),
      });
    }
  };
  // wishlist book
  const handleWishlistBook = () => {
    if (auth) {
      API.axios
        .post(API.createApiRoute("wishlist", { BOOK_ID: id }))
        .then((response) => {
          setServerMessages({
            ...serverMessages,
            success: response?.data.message,
          });
          setIsBookMarked(!isBookMarked);
        })
        .catch((error) => {
          setServerMessages({
            ...serverMessages,
            error: error?.response?.data?.message,
          });

          setPopupConfig({
            ...popupConfig,
            show: true,
            message: `${t("server_messages.unknown_error")}`,
          });
        });
    } else {
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("book_details.wishlist_popup"),
      });
    }
  };

  // post book note
  const handleBookNote = (e) => {
    e.preventDefault();

    if (bookNoteValue === "") {
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("book_details.book_notes.empty_note_error"),
      });

      return;
    }

    const formData = new FormData();
    formData.append("book_id", id);
    formData.append("comment", bookNoteValue);

    API.axios
      .post(API.createApiRoute("myBookNotes"), formData)
      .then((response) => {
        setServerMessages({
          ...serverMessages,
          success: response?.data.message,
        });
        // taking the last added response object from server and spreading all other objects
        setAllNotes([response?.data?.note, ...allNotes]);
      })
      .catch((error) => {
        setServerMessages({
          ...serverMessages,
          error: error?.response?.data?.message,
        });
      });
    setBookNoteValue("");
  };

  // delete note
  const deleteBookNote = (noteId) => {
    const formData = new FormData();
    formData.append("book_id", id);

    API.axios
      .post(API.createApiRoute("bookNoteDelete", { NOTE_ID: noteId }), formData)
      .then((response) => {
        // created new array with all notes except the current note
        const updatedBookNotes = allNotes.filter(
          (eachNote) => eachNote.id !== noteId
        );
        setAllNotes(updatedBookNotes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <BookDetailsContainer>
      <StyledBookDetailsLayout>
        <StyledBookDetailsInnerWrap>
          <StyledImageContainer>
            <img src={currenBookDetails?.data?.data?.image} />
          </StyledImageContainer>
          <StyledTitleAuthorContainer>
            <h3 className="title">{currenBookDetails?.data?.data?.title}</h3>
            {currenBookDetails?.data?.data?.authors.map((author) => (
              <p className="author" key={author.id}>
                {author.name} {author.surname}
              </p>
            ))}
            {isLaptop && (
              <StyledBookInfoContainer>
                <StyledBookInfo>
                  <li>
                    <p>
                      {parseInt(
                        currenBookDetails?.data?.data?.avg_rating
                      ).toFixed()}
                      <span>/{starIconsTotal}</span>
                    </p>
                    <span>{t("book_details.book_rate")}</span>
                  </li>
                  <li>
                    <p>{currenBookDetails?.data?.data?.pages}</p>
                    <span>{t("book_details.book_pages")}</span>
                  </li>
                  <li>
                    <p>
                      {currenBookDetails?.data?.data?.available_copies}
                      <span>/{currenBookDetails?.data?.data?.copies}</span>
                    </p>
                    <span>{t("book_details.book_copies")}</span>
                  </li>
                </StyledBookInfo>
                <StyledBookDetailsMoreInfo>
                  <li className="book-info-text">
                    <span>{t("book_details.category_name")}:</span>
                    {currenBookDetails?.data?.data?.category}
                  </li>
                  <li className="book-info-text">
                    <span>{t("book_details.language")}:</span>
                    {currenBookDetails?.data?.data?.language.mk}
                  </li>
                  <li className="book-info-text">
                    <span>{t("book_details.publisher")}:</span>
                    {currenBookDetails?.data?.data?.publisher}
                  </li>
                  <li>
                    <span>{t("book_details.year")}:</span>
                    {currenBookDetails?.data?.data?.year}
                  </li>
                  <li className="book-info-text">
                    <span>{t("book_details.isbn_number")}:</span>
                    {currenBookDetails?.data?.data?.isbn}
                  </li>
                </StyledBookDetailsMoreInfo>
              </StyledBookInfoContainer>
            )}
          </StyledTitleAuthorContainer>
        </StyledBookDetailsInnerWrap>

        {currenBookDetails?.data?.data?.types.some(
            (type) => type.type === "paperback" || "audio" || "digital") &&
            <StyledBookTypeButtonsContainer>
          {!isReserved &&
          currenBookDetails?.data?.data?.types.some(
            (type) => type.type === "paperback"
          ) ? (
            <StyledInnerButtonWrap>
              <Button
                text={<BsBookmarkCheck />}
                width={"100%"}
                className={"btnBookDetails m-top"}
                onClick={handleReserveBook}
              />
              <p style={{ color: "white" }}>{t("book_details.reserve_btn")}</p>
            </StyledInnerButtonWrap>
          ) : null}
          {auth &&
          currenBookDetails?.data?.data?.types.some(
            (type) => type.type === "audio"
          ) ? (
            <StyledInnerButtonWrap>
              <Button
                type={"button"}
                text={<FaHeadphones />}
                variation={"filled"}
                width={"100%"}
                className={"btnBookDetails m-top"}
                onClick={() => setIsAudioBook(true)}
              />
              <p style={{ color: "white" }}>
                {t("book_details.audio_book_button")}
              </p>
            </StyledInnerButtonWrap>
          ) : null}

          {auth &&
          currenBookDetails?.data?.data?.types.some(
            (type) => type.type === "digital"
          ) ? (
            <StyledInnerButtonWrap>
              <Link to={`/book/${id}/e-book`}>
                <Button
                  type={"button"}
                  text={<BiBookReader />}
                  variation={"filled"}
                  width={"100%"}
                  className={"btnBookDetails m-top"}
                />
                <p style={{ color: "white" }}>
                  {t("book_details.read_book_button")}
                </p>
              </Link>
            </StyledInnerButtonWrap>
          ) : null}
        </StyledBookTypeButtonsContainer>}

        <Bookmark
          colored={isBookMarked}
          onChange={handleWishlistBook}
          checked={isBookMarked}
        />
      </StyledBookDetailsLayout>
      {isAudioBook ? (
        <AudioPlayer
          audioFiles={currentBookResources?.audio}
          displayed={isAudioBook}
          setIsAudioBook={setIsAudioBook}
          imgUrl={currenBookDetails?.data?.data?.image}
          title={currenBookDetails?.data?.data?.title}
          author={currenBookDetails?.data?.data?.authors.map((author) => (
            <p className="author-text" key={author.id}>
              {author.name} {author.surname}
            </p>
          ))}
        />
      ) : null}
      {!isLaptop && (
        <StyledBookInfoContainer>
          <StyledBookInfo>
            <li>
              <p>
                {parseInt(currenBookDetails?.data?.data?.avg_rating).toFixed()}
                <span>/{starIconsTotal}</span>
              </p>
              <span>{t("book_details.book_rate")}</span>
            </li>
            <li>
              <p>{currenBookDetails?.data?.data?.pages}</p>
              <span>{t("book_details.book_pages")}</span>
            </li>
            <li>
              <p>
                {currenBookDetails?.data?.data?.available_copies}
                <span>/{currenBookDetails?.data?.data?.copies}</span>
              </p>
              <span>{t("book_details.book_copies")}</span>
            </li>
          </StyledBookInfo>
          <StyledBookDetailsMoreInfo>
            <li className="book-info-text">
              <span>{t("book_details.category_name")}:</span>
              {currenBookDetails?.data?.data?.category}
            </li>
            <li className="book-info-text">
              <span>{t("book_details.language")}:</span>
              {currenBookDetails?.data?.data?.language.mk}
            </li>
            <li className="book-info-text">
              <span>{t("book_details.publisher")}:</span>
              {currenBookDetails?.data?.data?.publisher}
            </li>
            <li>
              <span>{t("book_details.year")}:</span>
              {currenBookDetails?.data?.data?.year}
            </li>
            <li className="book-info-text">
              <span>{t("book_details.isbn_number")}:</span>
              {currenBookDetails?.data?.data?.isbn}
            </li>
          </StyledBookDetailsMoreInfo>
        </StyledBookInfoContainer>
      )}

      <StyledMainBookDetailsContainer>
        <StyledBookDescription>
          <p
            dangerouslySetInnerHTML={{
              __html: currenBookDetails?.data?.data?.description,
            }}
          />
        </StyledBookDescription>
        <StyledAboutTheAuthor>
          <StyledTitle>{t("book_details.about_author")}</StyledTitle>
          {currenBookDetails?.data?.data?.authors.map((author) => (
            <div className="author-container" key={author.id}>
              <div>
                <img src={author.avatar} alt="Author Avatar" />
              </div>
              <h6>
                {author.name} {author.surname}
              </h6>

              <div
                dangerouslySetInnerHTML={{
                  __html: author.bio,
                }}
              />
            </div>
          ))}
        </StyledAboutTheAuthor>
        {/* show Notes section if user is logged in */}
        {auth && (
          <article>
            <AddBookNote
              textNote={bookNoteValue}
              onChange={(e) => setBookNoteValue(e.target.value)}
              onSubmit={handleBookNote}
            />
            {allNotes.length > 0 && (
              <StyledTitle>
                {t("book_details.book_notes.notes_label")}
              </StyledTitle>
            )}
            <StyledBookNoteContainer>
              {allNotes?.map((bookNote) => {
                return (
                  <MyBookNoteCard
                    key={bookNote.id}
                    noteId={bookNote.id}
                    content={bookNote?.comment}
                    date={bookNote?.created_at}
                    bookId={id}
                    deleteNote={deleteBookNote}
                    setAllNotes={setAllNotes}
                  />
                );
              })}
            </StyledBookNoteContainer>
          </article>
        )}
        {myReview ? (
          <StyledReviews>
            <StyledTitle>
              {t("book_details.reviews.my_review.title")}
            </StyledTitle>
            {!myReview?.is_approved && (
              <p className="error-text">
                {t("book_details.reviews.my_review.error_message")}
              </p>
            )}
            <ReviewNoteCard
              key={myReview.id}
              name={myReview.name}
              surname={myReview.surname}
              rating={myReview.rating}
              content={myReview.comment}
            />
            <Button
              type="button"
              variation={"filled"}
              className={"addNote"}
              text={t("book_details.reviews.delete_review_btn")}
              onClick={() =>
                setPopupConfig({
                  ...popupConfig,
                  show: true,
                  confirmation: true,
                  message: t("book_details.reviews.delete_my_review"),
                  approveAction: () => {
                    handleDeleteBookReview();
                  },
                })
              }
            />
          </StyledReviews>
        ) : (
          <StyledReviews>
            <article>
              <h3>{t("book_details.reviews.review_title")}</h3>
              <form onSubmit={onBookReviewSubmit}>
                <label className="styled-label">
                  {t("book_details.reviews.review_labels.stars")}
                </label>
                <StyledStarIconBox>
                  <StarRatesContainer>
                    {Array(starIconsTotal)
                      .fill()
                      .map((star, index) => {
                        index += 1;
                        return (
                          <StarRatingIcon
                            key={index}
                            star={star}
                            index={index}
                            handleHover={setHoverdStarIndex}
                            // precedence has hovering event over selecting
                            // only icons with index < || = hovered/clicked index icon will be colored
                            colored={
                              index <=
                              (hoverdStarIndex || bookReview.selectedStarRate)
                            }
                            onClick={() => {
                              setBookReview({
                                ...bookReview,
                                selectedStarRate: index,
                              });
                            }}
                          />
                        );
                      })}
                  </StarRatesContainer>
                </StyledStarIconBox>
                <AddReviewNote
                  value={bookReview.bookReviewValue}
                  onChange={(e) => {
                    setBookReview({
                      ...bookReview,
                      bookReviewValue: e.target.value,
                    });
                  }}
                />
                <Button
                  type={"submit"}
                  text={t("book_details.reviews.submit_btn")}
                  variation={"filled"}
                  className={"addNote"}
                />
              </form>
            </article>
          </StyledReviews>
        )}

        <article>
          {currenBookDetails?.data?.data?.reviews.map((review) => (
            <ReviewNoteCard
              key={review.id}
              name={review.name}
              surname={review.surname}
              rating={review.rating}
              content={review.comment}
            />
          ))}
        </article>
      </StyledMainBookDetailsContainer>
    </BookDetailsContainer>
  );
}
