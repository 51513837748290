import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// local imports
import { API } from "../Consts/API";
import { GlobalContext } from "../Context/GlobalContext";
import InputComponent from "../Components/Input/Input";
import Button from "../Components/Button/Button";
import useValidateForm from "../Hooks/useValidateForm";
import ErrorMessage from "../Components/ErrorMessage/ErrorMessage";

const StyledSignin = styled.div`
  /* style for layout */
  display: flex;
  justify-content: center;
  align-items: center;
  /* style for form card */
  div:first-child {
    width: 300px;
    height: 300px;
    padding: 20px;
    margin-bottom: 15px;
  }
    /* style for title */
    .login-title {
      font-size: 44px;
      text-align: center;
      font-weight: 900;
      color: ${(props) => props.theme.yellow};
      padding: 15px;
    }
    /* style for 'go to register' section */
    div:not(:first-child) {
      margin: 20px 0px;
      font-size: 13px;
      text-align: center;
    }
    div:first-child a {
      color: ${(props) => props.theme.yellow};
      margin-left: 2px;
      &:hover {
        text-decoration: underline;
      }
    }
    
  // tablet
  @media (min-width: ${(props) => props.theme.tablet}) {
    min-height: 70vh;
  }

  // desktop
  @media (min-width: ${(props) => props.theme.desktop}) {
    div:first-child {
      width: 400px;
      padding: 30px;
    }

    .login-title {
      font-size: 54px;
    }
  }
`;

const SignIn = () => {
  // inital values for user credentials
  const emptyUserCredentials = { email: "", password: "" };
  // inital values for form errors
  const resetErrors = { email: [], password: [], additional: [] };
  // validation rules
  const validationRules = { email: "required", password: "required" };

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(emptyUserCredentials);
  const [errors, setErrors] = useState(resetErrors);
  const { validateForm, currentErrors } = useValidateForm();
  const { auth, user, setUser } = useContext(GlobalContext);

  /**
   * if user is already loged in, he can't access login page
   */
  useEffect(() => {
    // auth is false if there is no logged in user
    if (auth) {
      navigate("/");
    }
  }, [auth]);

  // handle form submit
  const handleLoginFormSubmit = (e) => {
    e.preventDefault();

    // reset errors on every submit
    setErrors(resetErrors);

    // validate form
    if (!validateForm(validationRules, userInfo, resetErrors)) {
      setErrors(currentErrors);
      return;
    }

    // submit
    const formData = new FormData();
    formData.append("email", userInfo.email);
    formData.append("password", userInfo.password);

    API.axios
      .post(API.createApiRoute("login"), formData)
      .then((response) => {
        // reset form input values if successfully submited (user logged in)
        setUserInfo(emptyUserCredentials);

        /**
         * If user is logged in set token in local storage
         * and set user with setUser()
         */
        localStorage.setItem("userToken", response.data.data.token);
        setUser(response.data.data.user);
      })
      .catch((error) => {
        // validation errors
        if (error?.response?.status === 422) {
          setErrors(error.response.data.errors);
        } else if (error?.response?.status === 400) {
          // additional error - user not found
          setErrors({
            ...errors,
            additional: [error?.response?.data?.message],
          });
        } else {
          // additional error - unknown status
          setErrors({
            ...errors,
            additional: ["Настана грешка. Ве молиме пробајте подоцна."],
          });
        }
      });
  };

  /**
   * if user land on login, redirect to signin
   */
  useEffect(() => {
    if (location.pathname.indexOf("login") > -1) {
      navigate(API.createPath("signin"));
    }
  }, [location.pathname]);

  return (
    <StyledSignin>
      <div>
        <p className="login-title">{t("login.login_title")}</p>
        <form onSubmit={handleLoginFormSubmit}>
          <InputComponent
            type={"text"}
            className={"inputSignin"}
            width={"100%"}
            placeholder={t("login.placeholder.email")}
            required
            invalidInput={errors.email?.length > 0 ? true : false}
            name={"email"}
            id={"email"}
            value={userInfo.email}
            onChange={(e) => {
              setUserInfo({ ...userInfo, email: e.target.value });
              setErrors({ ...errors, email: [] });
            }}
          />
          {errors?.email?.length > 0 &&
            errors.email.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          <InputComponent
            type={"password"}
            className={"inputSignin"}
            width={"100%"}
            placeholder={t("login.placeholder.password")}
            required
            invalidInput={errors.password?.length > 0 ? true : false}
            name={"password"}
            id={"password"}
            value={userInfo.password}
            onChange={(e) => {
              setUserInfo({ ...userInfo, password: e.target.value });
              setErrors({ ...errors, password: [] });
            }}
          />
          {errors?.password?.length > 0 &&
            errors.password.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          {errors?.additional?.length > 0 &&
            errors.additional.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          <Button
            width={"100%"}
            type={"submit"}
            variation={"filled"}
            text={t("login.login_btn")}
            className={"loginBtn"}
          />
        </form>
        <div>
          <span>{t("login.navigate_register.question_check")}</span>
          <Link to={"/register"}>
            {t("login.navigate_register.link_register")}
          </Link>
        </div>
      </div>
    </StyledSignin>
  );
};
export default SignIn;
