import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// local imports
import StarRatingIcon from "../Icon/StarRatingIcon";

// styled-components
const StyledReviewNoteCard = styled.article`
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    font-size: ${(props) => props.theme.normalText};
    font-style: italic;
    margin: 8px 0;
    white-space: break;
  }

  a.read-more {
    border-bottom: 3px solid ${(props) => props.theme.lightBlack};
    font-size: ${(props) => props.theme.normalText};
  }
  .show-more {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media (min-width: ${(props) => props.theme.laptop}) {
    a.read-more {
      display: none;
    }
    .show-more {
      -webkit-line-clamp: unset;
    }
  }
`;
const StyledNameReviewer = styled.span`
  color: ${(props) => props.theme.black};
  font-size: ${(props) => props.theme.normalText};
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const ReviewNoteCard = ({ name, surname, rating, content }) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const { t } = useTranslation();
  return (
    <StyledReviewNoteCard>
      <StyledNameReviewer>
        {name} {surname}
      </StyledNameReviewer>
      {Array(5)
        .fill()
        .map((_, index) => {
          index += 1;
          return (
            <StarRatingIcon
              key={index}
              index={index}
              rating={rating}
              // only icons with index < || = to the rating received from backend will be colored
              colored={index <= rating}
            />
          );
        })}

      <p className={!isReadMore && "show-more"}>{content}</p>
      {/* show ReadMore button only if the content is more than 100 characters */}

      {content?.length > 100 && (
        <a onClick={() => setIsReadMore(!isReadMore)} className="read-more">
          {t("show_more_btn.const_text")}
          {` ${
            !isReadMore
              ? t("show_more_btn.variable_1")
              : t("show_more_btn.variable_2")
          }`}
        </a>
      )}
    </StyledReviewNoteCard>
  );
};
export default ReviewNoteCard;
