import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrLinkNext } from "react-icons/gr";
import styled from "styled-components";
import { GlobalContext } from "../../Context/GlobalContext";
import Button from "../Button/Button";

const StyledForm = styled.form`
  width: clamp(275px, 50%, 450px);
  padding: 2rem;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  label {
    display: block;
  }

  input[type="number"] {
    color: black;
    margin-top: 2rem;
    border: none;
    box-shadow: 0 0px 5px 0 ${(props) => props.theme.lightBlack};
    border-radius: 8px;
    padding: 0.3rem 0.8rem;
    outline: none;
    width: 60%;
    margin-right: 1rem;
    display: inline-block;
  }
  input[type="number"]:focus {
    box-shadow: 0 0px 5px 0 orangered;
  }
`;

const JumpToPage = ({ pageNumber, setPageNumber, setIsJumpPageOpen }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { popupConfig, setPopupConfig } = useContext(GlobalContext);

  const onJumpToPage = (e) => {
    e.preventDefault();
    if (value === "") {
      setIsJumpPageOpen(false);
      setPopupConfig({
        ...popupConfig,
        show: true,
        message: t("e-book.jump_to_page_pop_up"),
      });
    } else {
      setPageNumber(parseInt(value));
      setValue("");
      setIsJumpPageOpen(false);
    }
  };
  return (
    <StyledForm onClick={(e) => e.stopPropagation()} onSubmit={onJumpToPage}>
      <label htmlFor="">
        {t("e-book.jump_page_label_1")}
        {pageNumber} <br /> <br />
        {t("e-book.jump_page_label_2")}
      </label>
      <input
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        type={"submit"}
        text={<GrLinkNext />}
        width={"100%"}
        className={"pageBtn findPage"}
      />
    </StyledForm>
  );
};

export default JumpToPage;
