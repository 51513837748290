import React, { useContext, useEffect, useState } from "react";
// local imports
import { GlobalContext } from "../Context/GlobalContext";
import { FaArrowCircleUp } from "react-icons/fa";
import BookCard from "../Components/Card/BookCard";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import styled from "styled-components";

const StyledBookCardContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  width: 90%;
  margin: 50px auto;

  // tablet
  @media only screen and (min-width: ${(props) => props.theme.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  // desktop
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 80%;
  }
`;

const StyledScroll = styled.span`
  position: fixed;
  font-size: 30px;
  right: 20px;
  bottom: 30px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 10;
  padding: 15px;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 1;

  &:active {
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
const Home = () => {
  const { filteredBooks } = useContext(GlobalContext);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);

    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <HeroBanner />
      <StyledBookCardContainer>
        {filteredBooks?.data?.map((book) => (
          <BookCard key={book.id} book={book} width={"35px"} />
        ))}
      </StyledBookCardContainer>

      <StyledScroll>
        <FaArrowCircleUp
          onClick={scrollTop}
          style={{ height: 40, display: showScroll ? "flex" : "none" }}
        />
      </StyledScroll>
    </>
  );
};
export default Home;
