import styled from "styled-components";

const Wrapper = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Wrapper;
