import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// local imports
import styled from "styled-components";
import Button from "../Components/Button/Button";
import InputComponent from "../Components/Input/Input";
import useValidateForm from "../Hooks/useValidateForm";
import ErrorMessage from "../Components/ErrorMessage/ErrorMessage";
import { API } from "../Consts/API";
import { GlobalContext } from "../Context/GlobalContext";

const StyledRecommendBook = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div:first-child {
    width: 300px;
    height: 300px;
    padding: 20px;
    margin-bottom: 15px;
  }
  /* style for title */
  > div > p {
    font-size: 29px;
    text-align: center;
    font-weight: 900;
    color: ${(props) => props.theme.yellow};
    padding: 15px;
  }

  // tablet
  @media (min-width: ${(props) => props.theme.tablet}) {
    min-height: 70vh;
  }

  // desktop
  @media (min-width: ${(props) => props.theme.desktop}) {
    div:first-child {
      width: 400px;
      padding: 30px;
    }

    > div > p {
      font-size: 38px;
    }
  }
`;

const RecommendBook = () => {
  const { t } = useTranslation();
  const { popupConfig, setPopupConfig } = useContext(GlobalContext);
  // inital values for book inputs
  const emptyBookRecommendationValues = { title: "", author: "" };
  // inital values for form errors
  const resetErrors = { title: [], author: [], additional: [] };
  // validation rules
  const validationRules = { title: "required", author: "required" };

  const [bookRecommendation, setBookRecommendation] = useState(
    emptyBookRecommendationValues
  );
  const [errors, setErrors] = useState(resetErrors);
  const { validateForm, currentErrors } = useValidateForm();

  const handleRecommendBookFormSubmit = (e) => {
    e.preventDefault();
    // reset errors on every submit
    setErrors(resetErrors);

    // form validation
    if (!validateForm(validationRules, bookRecommendation, resetErrors)) {
      setErrors(currentErrors);
      return;
    }

    const formData = new FormData();
    formData.append("title", bookRecommendation.title);
    formData.append("author", bookRecommendation.author);

    API.axios
      .post(API.createApiRoute("bookProposals"), formData)
      .then((response) => {
        // reset form input values if successfully submited
        setBookRecommendation(emptyBookRecommendationValues);
        // show success message
        setPopupConfig({
          ...popupConfig,
          show: true,
          message: response?.data?.message,
        });
      })
      .catch((error) => {
        // validation errors
        if (error?.response?.status === 422) {
          setErrors(error.response.data.errors);
        } else {
          // additional error - unknown status
          setErrors({
            ...errors,
            additional: [`${t("server_messages.unknown_error")}`],
          });
        }
      });
  };

  return (
    <StyledRecommendBook>
      <div>
        <p>{t("recommend_book.title")}</p>
        <form onSubmit={handleRecommendBookFormSubmit}>
          <InputComponent
            type={"text"}
            className={"inputSignin"}
            width={"100%"}
            placeholder={t("recommend_book.placeholders.title")}
            required
            invalidInput={errors.title?.length > 0 ? true : false}
            name={"title"}
            id={"title"}
            value={bookRecommendation.title}
            onChange={(e) => {
              setBookRecommendation({
                ...bookRecommendation,
                title: e.target.value,
              });
              setErrors({ ...errors, title: [] });
            }}
          />
          {errors?.title?.length > 0 &&
            errors.title.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          <InputComponent
            type={"text"}
            className={"inputSignin"}
            width={"100%"}
            placeholder={t("recommend_book.placeholders.author")}
            required
            invalidInput={errors.author?.length > 0 ? true : false}
            name={"title"}
            id={"title"}
            value={bookRecommendation.author}
            onChange={(e) => {
              setBookRecommendation({
                ...bookRecommendation,
                author: e.target.value,
              });
              setErrors({ ...errors, author: [] });
            }}
          />
          {errors?.author?.length > 0 &&
            errors.author.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          {errors?.additional?.length > 0 &&
            errors.additional.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          <Button
            width={"100%"}
            type={"submit"}
            variation={"filled"}
            text={t("recommend_book.button")}
            className={"loginBtn"}
          />
        </form>
      </div>
    </StyledRecommendBook>
  );
};

export default RecommendBook;
