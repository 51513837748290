import React, { useEffect, useState } from "react";

const useCustomHeight = () => {
  const [heightVh, setHeightVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setHeightVh(window.innerHeight);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return heightVh;
};

export default useCustomHeight;
