import React from "react";
import styled from "styled-components";
// local imports
import theme from "../../Consts/Theme";

const StyledButton = styled.button`
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  cursor: pointer;

  // css class in HamburgerMenu component
  &.btnMenu {
    background-color: transparent;
    color: ${(props) => props.theme.white};
    border: none;
    outline: none;
    padding: 15px 0;
    font-size: ${theme.largeText};

    &:hover {
      color: ${(props) => props.theme.altYellow};
    }
  }

  // css class in HamburgerMenu component
  &.loginBtn {
    background-color: ${(props) => props.className && props.theme.altYellow};
    box-shadow: 5px 5px 15px 0 ${(props) => props.theme.gray};
    border-radius: 25px;
    border: none;
    outline: none;
    display: inline-block;
    width: ${({ width }) => width};
    padding: 15px 25px;
    margin-top: 50px;
    font-size: ${theme.largeText};
  }

  &.lg-btn {
    margin-top: 0;
    padding: 5px 10px;
    box-shadow: none;
    align-self: center;
    display: none;
  }

  &.btnBookDetails {
    padding: 5px 8px;
    display: inline-block;
    background-color: ${(props) => props.theme.altYellow};
    border: none;
  }
  &.pageArrow {
    outline: none;
    border: none;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"}
  }
  &.zoomBtn {
    outline: none;
    border: none;
    cursor: pointer;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.pageBtn {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-self: center;

    svg path {
      margin-right: 3px;
    }
  }
  &.findPage {
    border: none;
    box-shadow: 2px 4px 8px 4px ${(props) => props.theme.gray};
    cursor: pointer;
  }
  &.findPage:active {
    box-shadow: 2px 4px 20px 4px ${(props) => props.theme.gray};
  }
  &.addNote {
    padding: 8px;
    display: inline-block;
    background-color: ${(props) => props.theme.altYellow};
    outline: none;
    border: none;
    box-shadow: 3px 4px 2px 0 ${(props) => props.theme.gray};
  }
  // styles for pop up buttons on delete note
  &.confirm-delete-btn {
    background-color: ${(props) => props.theme.lightBlack};
    color: ${(props) => props.theme.white};
    display: inline-block;
    padding: 5px 18px;
    margin-left: 5px;
  }
  &.moreOptions {
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    font-size: 1rem;
    margin-left: 1rem;
  }
  &.book-details-more {
    border: none;
    outline: none;
    background-color: transparent;
    svg {
      font-size: 20px;
    }
  }

  @media (min-width: ${(props) => props.theme.laptop}) {
    &.lg-btn {
      display: block;
    }
    &.btnMenu,
    &.loginBtn {
      font-size: 16px;
    }
  }
`;

const Button = ({
  type,
  onClick,
  className,
  text,
  width,
  checked,
  variation,
  disabled,
}) => {
  return (
    <StyledButton
      type={type && type}
      onClick={onClick}
      className={className}
      width={width}
      variation={variation}
      disabled={disabled}
      checked={checked}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
