import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { FaSearch, FaTimes } from "react-icons/fa";
// local imports
import { GlobalContext } from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { API } from "../../Consts/API";

//styled components
const StyledSearchBox = styled.div`
  ${(props) =>
    props.variant === "large"
      ? css`
          border: 1px solid ${(props) => props.theme.black};
          color: ${(props) => props.theme.black};
          width: 80%;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
        `
      : props.variant === "small" &&
        css`
          border: 1px solid ${(props) => props.theme.altYellow};
          color: ${(props) => props.theme.altYellow};
          width: 100px;
          justify-content: end;
          margin: 5px;
          transition-duration: 0.3s;
          transition-property: width;
          transition-timing-function: linear;
          :focus-within {
            width: 100%;
          }
          // laptop
          @media only screen and (min-width: ${(props) => props.theme.laptop}) {
            :focus-within {
              /* width: 50%; */
              width: ${(props) => (props.searchValue ? "100%" : "50%")};
            }
          }
        `}

  display: flex;
  justify-content: end;
  align-items: center;
  border-radius: 25px;
  height: 40px;
  padding: 15px 5px;
  position: relative;

  input {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    color: ${(props) =>
      props.variant === "large" ? props.theme.black : props.theme.altYellow};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  span {
    font-size: 18px;
    margin-right: 10px;
    .x-icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

// render searched results
const StyledSearchResults = styled.ul`
  display: block; // default
  position: absolute;
  top: ${(props) => (props.variant === "small" ? "58px" : "75px")};
  left: 30px;
  right: 30px;
  color: ${(props) => props.theme.darkGray};
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 0px 5px 0 ${(props) => props.theme.lightBlack};
  border-radius: 8px;
  padding: 8px;

  overflow-y: hidden;
  transition: height 0.9s linear;

  li {
    padding: 5px 0;
    cursor: pointer;
    border-radius: 8px;
  }

  li:hover {
    background-color: ${(props) => props.theme.gray};
  }

  li.no-result-found {
    text-transform: uppercase;
  }

  .search-icon {
    color: ${(props) => props.theme.gray};
  }

  // desktop
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    left: 50%;
    right: 300px;
  }
`;

const SearchBar = ({ variant, className }) => {
  const navigate = useNavigate();

  const { filterBookByTitle, filteredBooks, setFilteredBooks } =
    useContext(GlobalContext);
  const [searchValue, setSearchValue] = useState("");
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);

  return (
    <>
      <StyledSearchBox
        variant={variant}
        className={className}
        searchValue={searchValue}
      >
        <input
          value={searchValue}
          type="text"
          onChange={(e) => {
            setSearchValue(e.target.value);
            filterBookByTitle(e.target.value);
            setIsSearchResultOpen(true);
          }}
          onBlur={() => {
            setSearchValue(searchValue);
            setIsSearchResultOpen(false);
          }}
        />

        {!searchValue ? (
          <span>
            <FaSearch />
          </span>
        ) : (
          <span>
            <FaTimes
              className="x-icon"
              onClick={(e) => {
                setSearchValue("");
                filterBookByTitle("");
              }}
            />
          </span>
        )}
      </StyledSearchBox>

      {isSearchResultOpen && (
        <StyledSearchResults variant={variant}>
          {/* if there is array with object returned from server*/}
          {filteredBooks?.data?.length > 0 ? (
            filteredBooks?.data?.map((book) => (
              <>
                <li
                  key={book.id}
                  onClick={() => {
                    setSearchValue(searchValue);
                    setIsSearchResultOpen(false);
                  }}
                  // to navigate to bookDetails despite onBlur effect in SearchBar component
                  onMouseDown={() =>
                    navigate(
                      API.createPath("bookDetails", { BOOK_ID: book.id })
                    )
                  }
                >
                  <FaSearch className="search-icon" /> {book.title}
                </li>
              </>
            ))
          ) : (
            // if nothing is returned from server
            <li>
              <p className="no-result-found">
                Results for {`"${searchValue}"`} were not found
              </p>
            </li>
          )}
        </StyledSearchResults>
      )}
    </>
  );
};
export default SearchBar;
