import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { GlobalContext } from '../../Context/GlobalContext'

const ProtectedRoute = () => {
  const { auth } = useContext(GlobalContext);

  return auth ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoute