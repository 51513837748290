import React from "react";
import styled from "styled-components";
import { FaStar } from "react-icons/fa";

const StyledLabel = styled.label`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
  font-size: 13px;
  input {
    display: none;
  }
  svg {
    color: ${(props) =>
      props.colored ? props.theme.altYellow : props.theme.textGray};
    font-size: 16px;
  }

  @media (min-width: ${(props) => props.theme.tablet}) {
  }

  @media (min-width: ${(props) => props.theme.laptop}) {
  }

  @media (min-width: ${(props) => props.theme.desktop}) {
  }
`;

const StarRatingIcon = ({ index, handleHover, onClick, colored, rating }) => {
  // we determine if icon is in readonly mode by the rating that we receive from backend
  // if there is rating (true), then all events applied to the element have to stop
  return (
    <StyledLabel
      onMouseEnter={(e) => {
        if (rating) {
          e.stopPropagation();
        } else {
          handleHover(index);
        }
      }}
      onMouseLeave={(e) => {
        if (rating) {
          e.stopPropagation();
        } else {
          handleHover(undefined);
        }
      }}
      onClick={(e) => {
        if (rating) {
          e.stopPropagation();
        } else {
          onClick();
        }
      }}
      // colored prop receives boolean;
      // different conditions evaluate to true depending on the mode of the component;
      colored={colored}
    >
      <input type={"radio"} name="ratingStar" />
      <FaStar />
    </StyledLabel>
  );
};

export default StarRatingIcon;
