import React, { useEffect, useRef, useState } from "react";
import { FaPlay, FaPause, FaTimes } from "react-icons/fa";
import { AiFillStepBackward, AiFillStepForward } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Slider from "./Slider";
import useMediaCustom from "../../Hooks/useMediaCustom";

// styled-components
// style for the entire component layout
const StyledAudioComponent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${(props) => (props.displayed ? "block" : "none")};
  z-index: 1;
`;
// styles for the audio player container
const StyledAudioPlayerContainer = styled.div`
  width: clamp(275px, 60%, 600px);
  position: relative;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e7e7e7e7;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  padding: 0.8rem;
  margin-top: 1rem;
  text-align: center;

  .close-btn {
    color: ${(props) => props.theme.darkGray};
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

// styles for the title and author
const StyledBookInfo = styled.div`
  margin: 2rem 0;
  color: ${(props) => props.theme.lightBlack};
  font-size: ${(props) => props.theme.mediumText};
  margin: 180px 0 50px;
`;

// styles for the control buttons conatiner
const StyledControlButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
// styles for the for chapter container
const StyledChapters = styled.div`
  border-radius: 15px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 15px 0 10px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    display: ${(props) => (props.isMenuOpen ? "block" : "none")};
    margin-top: 15px;
    text-align: left;
    overflow-y: scroll;
    padding: 0 8px 0 3px;
    max-height: 400px;
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background-color: #d1caca;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
  ul li {
    margin-top: 12px;
    padding: 20px 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    cursor: pointer;
  }
  ul li:focus {
    font-weight: ${(props) => (props.currentAudioFile ? "bold" : "none")};
  }
`;

const StyledAudioTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.normalText};
  color: ${(props) => props.theme.darkGray};
`;
const StyledAudioButton = styled.button`
  border: none;
  outline: none;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
  background-color: ${(props) => props.theme.altYellow};

  :focus {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
  }
  :active {
    box-shadow: 2px 2px 10px rgba(164, 147, 15, 0.646);
  }

  svg {
    position: static;
    color: black;
    font-size: 1rem;
  }
`;
const StyledControlButton = styled.button`
  border: none;
  outline: none;
  margin-bottom: 0.5rem;

  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
  align-self: center;
  :focus {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  :active {
    box-shadow: 2px 2px 10px rgba(164, 147, 15, 0.646);
  }
`;

const StyledImgBox = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;

  img {
    width: inherit;
    object-fit: cover;
    height: 150px;
    border-radius: 50%;
    outline: none;
    animation: rotate 10s linear infinite;
    animation-play-state: ${(props) =>
      props.isPlaying ? "running" : "paused"};
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

const AudioPlayer = ({
  audioFiles,
  imgUrl,
  author,
  displayed,
  setIsAudioBook,
}) => {
  const isLaptop = useMediaCustom("(min-width: 768px)");
  const { t } = useTranslation();
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState();
  const [duration, setDuration] = useState();
  const [currentAudioFile, setCurrentAudioFile] = useState(audioFiles?.[0]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { id } = useParams();

  // reference
  const audioRef = useRef();

  const audioFileData = {
    id: "",
    file: "",
    time: "",
  };

  useEffect(() => {
    if (localStorage.getItem("audioFileData")) {
      const fromLS = JSON.parse(localStorage.getItem("audioFileData"));
      if (fromLS.id === id) {
        audioRef.current.currentTime = fromLS.time;
        setCurrentAudioFile(fromLS.file);
        return;
      } else {
        setCurrentAudioFile(currentAudioFile);
        setCurrentTime(0);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("audioFileData", JSON.stringify(audioFileData));
  }, [id, currentAudioFile]);

  // once the audio data is loaded
  const onLoadedMetadata = () => {
    const seconds = Math.floor(audioRef.current.duration);
    setDuration(seconds);

    if (!localStorage.getItem("audioFileData")) {
      setCurrentTime(0);
    }

    if (isPlaying) {
      audioRef.current.play();
    }
    if (localStorage.getItem("audioFileData")) {
      const fromLS = JSON.parse(localStorage.getItem("audioFileData"));
      if (fromLS.id === id) {
        setCurrentAudioFile(fromLS.file);
        setCurrentTime(fromLS.time);
        audioRef.current.currentTime = currentTime;
      } else {
        setCurrentAudioFile(currentAudioFile);
        setCurrentTime(0);
      }
    }
    setCurrentAudioFile(currentAudioFile);
  };

  // everytime the current time of the audio updates, set the current time on the actual time
  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  // when the audio ends, set it ready for the next audio file
  const onEnded = () => {
    setIsPlaying(true);

    // when the last audio finishes, set the first audio to be played next OR set the next one
    if (audioFiles[audioFiles.length - 1] === currentAudioFile) {
      setCurrentAudioFile(audioFiles[0]);
      setIsPlaying(false);
      audioRef.current.autoplay = false;
    } else {
      setCurrentAudioFile(
        audioFiles[
          audioFiles.findIndex(
            (audioFile) => audioFile.title == currentAudioFile.title
          ) + 1
        ]
      );
      setIsPlaying(true);
      audioRef.current.autoplay = true;
      audioRef.current.play();
    }
  };

  // if we click randomly on the progress track, we want that value to become currentTime of the audio
  const onProgressTimeChange = (progressReference) => {
    audioRef.current.currentTime = progressReference?.value;
  };

  // calculates the given time in hours, minutes, seconds
  const calcTime = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const returnedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor((durationInSeconds % 3600) % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${returnedHours}h:${returnedMinutes}m:${returnedSeconds}s`;
  };

  // toggle play/pause button
  const togglePlayPause = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      audioRef.current.play();
    } else {
      setIsPlaying(false);
      audioRef.current.pause();
    }
  };

  // backward 30 sec
  const backwardThirty = () => {
    audioRef.current.currentTime = currentTime - 30;
  };
  // forward 30 sec
  const forwardThirty = () => {
    audioRef.current.currentTime = currentTime + 30;
  };
  // close audio component
  const onCloseAudio = () => {
    setIsAudioBook(false);
    setIsPlaying(false);
    localStorage.setItem(
      "audioFileData",
      JSON.stringify({
        ...audioFileData,
        id: id,
        file: currentAudioFile,
        time: currentTime,
      })
    );
  };

  return (
    <StyledAudioComponent
      displayed={displayed}
      // to hide the component if clicked anywhere on the screen and store data in LS
      onClick={() => onCloseAudio()}
    >
      <StyledAudioPlayerContainer onClick={(e) => e.stopPropagation()}>
        <StyledImgBox isPlaying={isPlaying}>
          <img src={imgUrl} alt="" />
        </StyledImgBox>

        {!isLaptop && (
          <FaTimes className="close-btn" onClick={() => onCloseAudio()} />
        )}
        <StyledBookInfo>
          <h2>{currentAudioFile?.title}</h2>
          {author}
        </StyledBookInfo>

        <audio
          src={currentAudioFile?.link}
          ref={audioRef}
          onTimeUpdate={onTimeUpdate}
          onLoadedMetadata={onLoadedMetadata}
          onEnded={onEnded}
        ></audio>
        <StyledControlButtonsContainer>
          <StyledControlButton type="button" onClick={backwardThirty}>
            <AiFillStepBackward /> 30
          </StyledControlButton>
          <StyledAudioButton onClick={togglePlayPause}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </StyledAudioButton>
          <StyledControlButton type="button" onClick={forwardThirty}>
            30 <AiFillStepForward />
          </StyledControlButton>
        </StyledControlButtonsContainer>
        <Slider
          currentTime={currentTime}
          duration={duration}
          audioRef={audioRef}
          onChange={onProgressTimeChange}
          variation={"audioTrack"}
        />
        <StyledAudioTimeContainer>
          <span>{calcTime(currentTime)}</span>
          <span>{calcTime(duration)}</span>
        </StyledAudioTimeContainer>

        <StyledChapters
          className="audio-chapters"
          isMenuOpen={isMenuOpen}
          currentAudioFile={currentAudioFile}
        >
          <header onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <h2>{t("book_details.audio_chapters")}</h2>
            {isMenuOpen ? (
              <IoIosClose className="icon" />
            ) : (
              <MdKeyboardArrowDown className="icon" />
            )}
          </header>

          <ul>
            {audioFiles &&
              audioFiles?.map((audio) => (
                <li
                  key={audio.id}
                  onClick={() => {
                    setCurrentAudioFile(audio);
                    setIsPlaying(true);
                    audioRef.current.play();
                  }}
                >
                  {audio.title}
                </li>
              ))}
          </ul>
        </StyledChapters>
      </StyledAudioPlayerContainer>
    </StyledAudioComponent>
  );
};

export default AudioPlayer;
