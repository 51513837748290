import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// local imports
import { API } from "../Consts/API";
import { GlobalContext } from "../Context/GlobalContext";
import Input from "../Components/Input/Input";
import Button from "../Components/Button/Button";
import RadioButton from "../Components/RadioButton/RadioButton";
import useValidateForm from "../Hooks/useValidateForm";
import ErrorMessage from "../Components/ErrorMessage/ErrorMessage";
import InputComponent from "../Components/Input/Input";

// styled-components

// style for layout
const StyledRegister = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // style for form card
  div.register-box {
    width: 300px;
    padding: 20px;
    text-align: center;
    margin-bottom: 15px;

    // style for title
    p {
      font-size: 30px;
      color: ${(props) => props.theme.yellow};
      padding: 15px;
    }
    // style for select status card
    div.select-status {
      display: flex;
      border-radius: 10px;
      padding: 8px;
      margin: 10px 0;

      label:first-child {
        display: inline-block;
        margin-bottom: 15px;
        font-size: 13px;
      }
      div {
        margin-left: 10px;
      }
    }
    // style for checkbox div
    div.checkbox {
      display: flex;
      margin-top: 30px;
      font-size: 13px;
      text-align: left;

      label {
        margin-left: 15px;
      }
      input {
        align-self: start;
      }
    }

    // style for "to sign in page" div
    div.to-signin-page {
      margin: 20px 0px;
      font-size: 13px;
    }
    a {
      color: ${(props) => props.theme.yellow};
      margin-left: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  // tablet
  @media (min-width: ${(props) => props.theme.tablet}) {
    min-height: 90vh;
  }

  // desktop
  @media (min-width: ${(props) => props.theme.desktop}) {
    div.register-box {
      width: 400px;
      padding: 30px;
      // style for title
      p {
        font-size: 44px;
      }
    }
  }
`;

const Register = () => {
  // initial values for user credentials
  const userCredentials = {
    name: "",
    surname: "",
    email: "",
    password: "",
    role_id: "",
    agreeTerms: false,
  };
  // storing initial credentials in state
  const [user, setUser] = useState(userCredentials);

  // inital values for form errors
  const resetErrors = {
    name: [],
    surname: [],
    email: [],
    password: [],
    role_id: [],
    agreeTerms: [],
    additional: [],
  };
  // storing initial errors in state
  const [errors, setErrors] = useState(resetErrors);

  // validation rules
  const validationRules = {
    name: "required",
    surname: "required",
    email: "required",
    password: "required",
    role_id: "required",
    agreeTerms: "required",
  };
  // custom hook for validation
  const { validateForm, currentErrors } = useValidateForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { auth, popupConfig, setPopupConfig } = useContext(GlobalContext);

  /**
   * if user is already loged in, he can't access register page
   */
  useEffect(() => {
    // auth is false if there is no logged in user
    if (auth) {
      navigate("/");
    }
  }, [auth]);

  // on form submit
  const handleSubmitRegisterForm = (e) => {
    e.preventDefault();

    // reset errors on every submit
    setErrors(resetErrors);

    // if invalid unput update errors in state with currentErrors
    if (!validateForm(validationRules, user)) {
      setErrors(currentErrors);
      return;
    }

    // sending data to backend
    let formData = new FormData(); // creates empty object
    formData.append("name", user.name);
    formData.append("surname", user.surname);
    formData.append("email", user.email);
    formData.append("role_id", user.role_id);
    formData.append("password", user.password);

    API.axios
      .post(API.createApiRoute("register"), formData)
      .then((response) => {
        // ===register logic goes here ===

        // reset form input values if successfully submited
        setUser(userCredentials);
        setPopupConfig({
          ...popupConfig,
          show: true,
          message: response?.data?.message,
        });
      })
      .catch((error) => {
        // validation errors
        if (error?.response?.status === 422) {
          setErrors(error.response.data.errors);
        } else if (error?.response?.status === 400) {
          // additional error - server error
          setErrors({
            ...errors,
            additional: [error?.response?.data?.message],
          });
        } else {
          // additional error - unknown status
          setErrors({
            ...errors,
            additional: [t("server_messages.unknown_error")],
          });
        }
      });
  };

  return (
    <StyledRegister>
      <div className="register-box">
        <p>{t("register.register_title")}</p>
        <form onSubmit={handleSubmitRegisterForm}>
          <InputComponent
            name={"name"}
            id={"name"}
            className={"inputsRegister"}
            type={"text"}
            width={"100%"}
            placeholder={t("register.placeholder.name")}
            value={user.name}
            onChange={(e) => {
              setUser({ ...user, name: e.target.value });
              setErrors({ ...errors, name: [] });
            }}
            invalidInput={errors?.name?.length > 0 ? true : false}
            required
          />

          {errors?.name?.length > 0 &&
            errors.name.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}

          <InputComponent
            name={"surname"}
            id={"surname"}
            className={"inputsRegister"}
            type={"text"}
            width={"100%"}
            placeholder={t("register.placeholder.surname")}
            value={user.surname}
            onChange={(e) => {
              setUser({ ...user, surname: e.target.value });
              setErrors({ ...errors, surname: [] });
            }}
            invalidInput={errors?.surname?.length > 0 ? true : false}
            required
          />

          {errors?.surname?.length > 0 &&
            errors.surname.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}

          <InputComponent
            name={"email"}
            id={"email"}
            className={"inputsRegister"}
            type={"text"}
            width={"100%"}
            placeholder={t("register.placeholder.email")}
            value={user.email}
            invalidInput={errors?.email?.length > 0 ? true : false}
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
              setErrors({ ...errors, email: [] });
            }}
            required
          />
          {errors?.email?.length > 0 &&
            errors.email.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}

          <div className="select-status">
            <label htmlFor="">{t("register.user_status.status_label")}</label>
            <div>
              <RadioButton
                label={t("register.user_status.student")}
                id={"academy_student"}
                name={"role_id"}
                value={"2"}
                checked={user.role_id === "2"} // evaluates to true or false
                onChange={(e) => {
                  setUser({ ...user, role_id: e.target.value });
                  setErrors({ ...errors, role_id: [] });
                }}
              />
              <RadioButton
                label={t("register.user_status.employee")}
                id={"employee"}
                name={"role_id"}
                value={"1"}
                checked={user.role_id === "1"} // evaluates to true or false
                onChange={(e) => {
                  setUser({ ...user, role_id: e.target.value });
                  setErrors({ ...errors, role_id: [] });
                }}
              />
              <RadioButton
                label={t("register.user_status.instructor")}
                id={"instructor"}
                name={"role_id"}
                value={"3"}
                checked={user.role_id === "3"} // evaluates to true or false
                onChange={(e) => {
                  setUser({ ...user, role_id: e.target.value });
                  setErrors({ ...errors, role_id: [] });
                }}
              />
            </div>
          </div>
          {errors?.role_id?.length > 0 &&
            errors.role_id.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}

          <InputComponent
            className={"inputsRegister"}
            type={"password"}
            width={"100%"}
            placeholder={t("register.placeholder.password")}
            value={user.password}
            invalidInput={errors?.password?.length > 0 ? true : false}
            onChange={(e) => {
              setUser({ ...user, password: e.target.value });
              setErrors({ ...errors, password: [] });
            }}
            required
          />
          {errors?.password?.length > 0 &&
            errors.password.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}

          <div className="checkbox">
            <Input
              type={"checkbox"}
              id={"termsAndConditions"}
              name={"termsAndConditions"}
              checked={user.agreeTerms}
              onChange={() => {
                setUser({ ...user, agreeTerms: !user.agreeTerms });
                setErrors({ ...errors, agreeTerms: [] });
              }}
              required
            />

            <label htmlFor="termsAndConditions" name={"termsAndConditions"}>
              {t("register.agree_terms")}
            </label>
          </div>
          {errors?.agreeTerms?.length > 0 &&
            errors.agreeTerms.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          {errors?.additional?.length > 0 &&
            errors.additional.map((error) => (
              <ErrorMessage
                key={Math.floor(Math.random() * 500) + 1}
                message={error}
              />
            ))}
          <Button
            width={"100%"}
            type={"submit"}
            variation={"filled"}
            text={t("register.register_btn")}
            className={"loginBtn"}
          />
        </form>
        <div className="to-signin-page">
          <span>{t("register.navigate_login.question_check")}</span>
          <Link to={"/signin"}>{t("register.navigate_login.link_login")}</Link>
        </div>
      </div>
    </StyledRegister>
  );
};

export default Register;
