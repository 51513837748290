import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// local imports
import StarRatingIcon from "../Icon/StarRatingIcon";

// styled-component
const StyledBookCard = styled.div`
  width: 200px;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.lightBlack};
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  div {
    padding: 20px 10px;

    span {
      font-size: ${(props) => props.theme.smallText};
      color: ${(props) => props.theme.textGray};
    }
    p {
      font-size: ${(props) => props.theme.normalText};
      color: ${(props) => props.theme.textGray};
      margin-top: 5px;
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-top: 5px;
      color: ${(props) => props.theme.lightBlack};
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      position: relative;
    }
    h3:hover {
      display: block;
    }
  }
  div.iconBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .starContainer {
      display: flex;
      justify-content: left;
      padding: 0;
      margin-top: 8px;
    }
  }
`;

const BookCard = ({
  book: { id, image, title, authors, avg_rating, width },
}) => {
  // number of starts to be colored;
  // round decimal on one integer; result is string
  const rate = parseInt(avg_rating).toFixed();

  // stores the rating value that comes from backend
  const [rating, setRating] = useState(rate);

  // set rating value on every change of rate
  useEffect(() => {
    setRating(rating);
  }, [rate]);

  return (
    <StyledBookCard width={width}>
      <Link to={`/book/${id}`}>
        <img src={image} alt="Book Cover Image" />
      </Link>

      <div>
        <h3>{title}</h3>
        {authors.map((author) => (
          <p key={author.id}>
            {author.name} {author.surname}
          </p>
        ))}
        <div className="iconBox">
          <div className="starContainer">
            {Array(5)
              .fill()
              .map((_, index) => {
                index += 1;
                return (
                  <StarRatingIcon
                    key={index}
                    index={index}
                    rating={rating}
                    // only icons with index < || = to the rating received from backend will be colored
                    colored={index <= rating}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </StyledBookCard>
  );
};

export default BookCard;
