import React, { createContext, useEffect, useState } from "react";
// local imports
import { API } from "../Consts/API";

export const GlobalContext = createContext({});

export const Provider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [books, setBooks] = useState([]);
  const [authors, setAuthors] = useState([]);

  // stores all books + filtered
  const [filteredBooks, setFilteredBooks] = useState([]);

  // auth user and auth flag
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState(false);

  //Popup logic
  const [popupConfig, setPopupConfig] = useState({
    show: false,
    message: "",
    confirmation: false,
    approveAction: () => {},
    rejectAction: () => {},
  });

  //Navbar shown
  const [navbarConfig, setNavbarConfig] = useState({
    showNavbar: true,
  });

  // after ajax request is finished, books data is set in filteredBooks
  useEffect(() => {
    setFilteredBooks(books);
  }, [books]);

  // filter book by title search
  const filterBookByTitle = (value) => {
    if (value !== "") {
      const filteredBooksByTitle = books?.data?.filter((book) =>
        book.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredBooks({ data: filteredBooksByTitle });
    } else if (value === "" || !value) {
      setFilteredBooks(books);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("userToken");
    if (!token) {
      setUser({});
    } else {
      API.axios.get("/user").then((response) => {
        setUser(response.data);
      });
    }
  }, []);

  useEffect(() => {
    // if there is auth user, user.name will have value != null --> (set auth flag to true)
    if (user.name) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [user.name]);

  // get categories
  useEffect(() => {
    API.axios
      .get(API.createApiRoute("categories"))
      .then((response) => setCategories(response.data))
      .catch((error) => console.error(error));
  }, []);

  // get all books
  useEffect(() => {
    API.axios
      .get(API.createApiRoute("books"))
      .then((response) => {
        setBooks(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  // get all authors
  useEffect(() => {
    API.axios
      .get(API.createApiRoute("authors"))
      .then((response) => setAuthors(response.data))
      .catch((error) => console.error(error));
  }, []);

  /**
   *
   * on click on logout button:
   * send request to /logout api (this will remove auth user token from database)
   * and remove token from local storage
   *
   */
  const logout = () => {
    API.axios.get("/logout").then(() => {
      setUser({});
      localStorage.removeItem("userToken");
    });
  };

  const contextObj = {
    categories,
    authors,
    user,
    auth,
    popupConfig,
    filteredBooks,
    navbarConfig,
    logout,
    filterBookByTitle,
    setUser,
    logout,
    setPopupConfig,
    setNavbarConfig,
  };

  return (
    <GlobalContext.Provider value={contextObj}>
      {children}
    </GlobalContext.Provider>
  );
};
