import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledHero = styled.div`
  height: 60vh;
  position: relative;
  background-color: black;

  img {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    height: 100vh;
  }
`;

const StyledHeroText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
  text-align: center;

  // laptop
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: 50px;
  }
  // desktop
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 80%;
  }
`;

export default function HeroBanner() {
  const { t } = useTranslation();
  return (
    <StyledHero>
      <img src="./images/hero.jpg" alt="" />
      <StyledHeroText>
        <p>{t("home.banner_text")}</p>
      </StyledHeroText>
    </StyledHero>
  );
}
